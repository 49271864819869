import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { ContentItemType } from '@core/interfaces/ContentItemType';
import { Endpoints } from '@core/interfaces/Endpoints';
import { TECRProjectCategory, TECRProjectData, TECRSource } from '@core/interfaces/TECR';
import { TStatus } from '@core/interfaces/TFolderItem';

import { allowedStatuses } from '../../constants/status';
import { CommonService } from '@shared/services/common.service';
import { FoldersService } from '@shared/services/folders.service';

export interface TECRCredentials {
  name: string;
  password: string;
}

export interface TCredentialMetadata {
  value: string;
  name: string;
  slug: string;
  _id: string;
}

@Injectable({
  providedIn: 'root',
})
export class ECRService {
  private ECRProjectCategories: BehaviorSubject<TECRProjectCategory[]> = new BehaviorSubject<
    TECRProjectCategory[]
  >([]);
  private ECRCredentials: BehaviorSubject<TECRCredentials | null> =
    new BehaviorSubject<TECRCredentials | null>(null);

  constructor(
    private _http: HttpClient,
    private _commonService: CommonService,
    private _folderService: FoldersService
  ) {}

  public getECRProjectCategoriesObservable(): Observable<TECRProjectCategory[]> {
    return this.ECRProjectCategories.asObservable();
  }

  public getECRCredentialsObservable(): Observable<TECRCredentials | null> {
    return this.ECRCredentials.asObservable();
  }

  fetchECRCredentials(): void {
    const loginResponse = this._http.get<TCredentialMetadata>(Endpoints.ECR.fetchECRLogin.url);
    loginResponse.subscribe({
      error: err => {
        console.error(err);
      },
      next: loginRes => {
        const passwordResponse = this._http.get<TCredentialMetadata>(
          Endpoints.ECR.fetchECRPassword.url
        );
        passwordResponse.subscribe({
          error: err => {
            console.error(err);
          },
          next: passwordRes => {
            this.ECRCredentials.next({ name: loginRes.value, password: passwordRes.value });
          },
        });
      },
    });
  }

  updateECRCredentials(name: string, password: string, callback: () => void): void {
    const loginResponse = this._http.put(Endpoints.ECR.updateECRLogin.url, {
      slug: 'ecr_login',
      value: name,
      name: 'ECR Login',
    });
    loginResponse.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        const passwordResponse = this._http.put(Endpoints.ECR.updateECRPassword.url, {
          slug: 'ecr_password',
          value: password,
          name: 'ECR Password',
        });
        passwordResponse.subscribe({
          error: err => {
            console.error(err);
          },
          next: () => {
            this.ECRCredentials.next({ name, password });
            callback();
          },
        });
      },
    });
  }

  public fetchECRProjectData(
    folderName: string,
    folderStatus: string | undefined,
    projectId: string,
    ECRSource: TECRSource,
    callback: (folderID: string) => void
  ): void {
    const response = this._http.post<TECRProjectData>(
      Endpoints.ECR.fetchECRProjectData.url(projectId, ECRSource),
      {
        folderName,
      }
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const newFolder = {
          name: folderName,
          _id: response.folderID,
          children: [],
          icon: '',
          project: [],
          sourceCount: 0,
          status: (folderStatus as TStatus) ?? (allowedStatuses.ACTIVE as TStatus),
          sufix: 0,
          title: folderName,
          __type: ContentItemType.Folders,
        };
        this._folderService.setFolders(newFolder);
        callback(response.folderID);
      },
    });
  }

  public fetchECRProjectCategories(): void {
    this._commonService.setIsLoading(true);
    const response = this._http.get<TECRProjectCategory[]>(Endpoints.ECR.fetchECRProjects.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        this.ECRProjectCategories.next(response);
      },
      complete: () => {
        this._commonService.setIsLoading(false);
      },
    });
  }
}
