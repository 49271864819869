<div class="video-container" (mouseenter)="hideControls = false" (mouseleave)="hideControls = true">
  <div>
    <video
      #videoPlayer
      autoplay
      playsinline
      [width]="videoDimensions.width"
      [height]="videoDimensions.height"
    >
    </video>
  </div>

    <button *ngIf="!hideControls && !controlsProhibited" class="play-pause-btn" (click)="togglePlayPause()">
      <mat-icon>{{ isPlaying ? 'pause' : 'play_arrow' }}</mat-icon>
    </button>

</div>
