import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { MatMenuTrigger } from '@angular/material/menu';

@Component({
  selector: 'app-prompt-hints-menu',
  templateUrl: './prompt-hints-menu.component.html',
  styleUrls: ['./prompt-hints-menu.component.scss']
})
export class PromptHintsMenuComponent {
  @Input() promptHints: string[] = [];
  @Output() hintSelected = new EventEmitter<string>();
  @ViewChild(MatMenuTrigger) menuTrigger!: MatMenuTrigger;
  
  isMenuOpen = false;

  updateMessage(hint: string) {
    this.hintSelected.emit(hint);
  }

  onMenuOpened() {
    this.isMenuOpen = true;
  }

  onMenuClosed() {
    this.isMenuOpen = false;
  }
} 