import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Language } from '@core/interfaces/language';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { BotApi } from '@core/api/bots/api';
import { ContentItemType } from '@core/interfaces/ContentItemType';
import { Endpoints } from '@core/interfaces/Endpoints';
import {
  IAddBotCreatorResponse,
  TAddBotResponse,
  TBotCreator,
  TBotCreatorBody,
  TBotItemRequestBody,
  TBotType,
  TInnerBot,
  TInnerBotBody,
  TLanguage,
} from '@core/interfaces/TBot';
import { TBotItem, TBotItemHistory, TBotItemResponse } from '@core/interfaces/TBotItem';
import { TMetadataNew } from '@core/interfaces/TMetadata';
import { Icons } from 'app/enums/icons';
import { formatBot } from '@shared/utils/bots';
import { TBotModel } from '@core/api/bots/types';

export interface TBotConfigItem {
  displayName: string | null | undefined;
  slug: string;
  value: string;
  status: string;
  configType: BotConfigType;
}

export interface TBotConfig {
  configurations: TBotConfigItem[];
  instructions: TBotConfigItem[];
}

export type BotConfigType = 'configuration' | 'instruction';

@Injectable({
  providedIn: 'root',
})
export class BotsService {
  private bots: BehaviorSubject<TBotItem[]> = new BehaviorSubject<TBotItem[]>([]);
  private summaryBots: BehaviorSubject<TBotItem[]> = new BehaviorSubject<TBotItem[]>([]);
  private compareBots: BehaviorSubject<TBotItem[]> = new BehaviorSubject<TBotItem[]>([]);
  private botCreators: BehaviorSubject<TBotItem[]> = new BehaviorSubject<TBotItem[]>([]);
  private primarySummaryBotId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  private primaryCompareBotId: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  private primaryConversationBotId: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >(null);
  private botFinalMessage: BehaviorSubject<string | null> = new BehaviorSubject<string | null>(
    null
  );
  private currentBot: BehaviorSubject<TBotItem | null> = new BehaviorSubject<TBotItem | null>(null);
  private botTypes: BehaviorSubject<TBotType[]> = new BehaviorSubject<TBotType[]>([]);
  private isBotAnswerLoading: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  private currentBotConfig: BehaviorSubject<TBotConfig> = new BehaviorSubject<TBotConfig>({
    instructions: [],
    configurations: [],
  } as TBotConfig);
  private languages: BehaviorSubject<TLanguage[]> = new BehaviorSubject<TLanguage[]>([]);
  private botModels: BehaviorSubject<TBotModel[]> = new BehaviorSubject<TBotModel[]>([]);
  private botCreator: BehaviorSubject<TBotCreator> = new BehaviorSubject<TBotCreator>({
    _id: '',
    name: '',
    preSearch: '',
    language: '' as Language,
    description: '',
    behaviour: '',
    config: '',
    instruction: '',
    bot_status: '',
    display_resources: false,
    maximum_resources: 0,
    maximum_publications: 0,
    bots: [],
  });

  constructor(private _http: HttpClient) {}

  public getBotsObservable(): Observable<TBotItem[]> {
    return this.bots.asObservable();
  }

  public getBotsModelsObservable(): Observable<TBotModel[]> {
    return this.botModels.asObservable();
  }

  public getCurrentBotObservable(): Observable<TBotItem | null> {
    return this.currentBot.asObservable();
  }

  public getSummaryBotsObservable(): Observable<TBotItem[]> {
    return this.summaryBots.asObservable();
  }

  public getPrimarySummaryBotIdObservable(): Observable<string | null> {
    return this.primarySummaryBotId.asObservable();
  }

  public getCompareBotsObservable(): Observable<TBotItem[]> {
    return this.compareBots.asObservable();
  }

  public getPrimaryCompareBotIdObservable(): Observable<string | null> {
    return this.primaryCompareBotId.asObservable();
  }

  public getBotCreatorsObservable(): Observable<TBotItem[]> {
    return this.botCreators.asObservable();
  }

  public getPrimaryConversationBotIdObservable(): Observable<string | null> {
    return this.primaryConversationBotId.asObservable();
  }

  public getBotTypesObservable(): Observable<TBotType[]> {
    return this.botTypes.asObservable();
  }

  public getCurrentBotConfigObservable(): Observable<TBotConfig> {
    return this.currentBotConfig.asObservable();
  }

  public getIsBotAnswerLoadingObservable(): Observable<boolean> {
    return this.isBotAnswerLoading.asObservable();
  }

  public getLanguagesObservable(): Observable<TLanguage[]> {
    return this.languages.asObservable();
  }

  public getBotCreatorObservable(): Observable<TBotCreator> {
    return this.botCreator.asObservable();
  }

  public getBotFinalMessageObservable(): Observable<string | null> {
    return this.botFinalMessage.asObservable();
  }

  public addBotCreator(newItem: TBotCreatorBody, callback: () => void): void {
    const response = this._http.post<IAddBotCreatorResponse>(
      Endpoints.bot.createNewBotCreator.url,
      newItem
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const formattedBot = {
          __type: ContentItemType.Bots,
          botData: undefined,
          group: { id: '', name: '' },
          icon: Icons.BotEyeSmall,
          title: response.bot.name,
          type: response.bot.botType,
          botType: response.bot.botType,
          botModel: response.bot.botModel,
          bot_status: response.bot.bot_status,
          description: response.bot.description,
          preSearch: response.bot.preSearch,
          _id: response.bot._id,
          botCreatorId: response.botsCreator._id,
        } as TBotItem;
        this.bots.next([...this.bots.value, formattedBot]);
        callback();
      },
    });
  }

  public addNewPredefinedBot(newItem: TBotItemRequestBody, callback: () => void): void {
    const response = this._http.post<TAddBotResponse>(Endpoints.bot.addBot.url, newItem);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const formattedBots = {
          __type: ContentItemType.Bots,
          botData: undefined,
          group: { id: '', name: '' },
          icon: Icons.BotEyeSmall,
          title: response.name,
          type: response.botType,
          bot_status: response.bot_status,
          botModel: response.botModel,
          description: response.description,
          _id: response._id,
          display_resources: response.display_resources,
          maximum_resources: response.maximum_resources,
          maximum_publications: response.maximum_publications,
        } as TBotItem;
        this.bots.next([...this.bots.value, formattedBots]);
        callback();
      },
    });
  }

  public fetchRawBotsData(): Observable<TBotItemResponse[]> {
    return this._http.get<TBotItemResponse[]>(Endpoints.bot.fetchBots.url);
  }

  public fetchBots(): void {
    const response = this._http.get<TBotItemResponse[]>(Endpoints.bot.fetchBots.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const formattedBots: TBotItem[] = [];
        response.forEach(res => {
          formattedBots.push(formatBot(res));
        });
        this.bots.next(formattedBots);
      },
    });
  }

  public getBotById(id: string): void {
    const response = this._http.get<TBotItemResponse>(Endpoints.bot.getBotById.url(id));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        this.currentBot.next(formatBot(response));
      },
    });
  }

  public fetchSummaryBots(): void {
    const response = this._http.get<TBotItemResponse[]>(
      Endpoints.bot.getBotsByType.url('summaryBot')
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const formattedBots: TBotItem[] = [];
        response.forEach(res => {
          formattedBots.push(formatBot(res));
        });
        this.summaryBots.next(formattedBots);
      },
    });
  }

  public fetchBotCreators(): void {
    const response = this._http.get<TBotItemResponse[]>(
      Endpoints.bot.getBotsByType.url('botCreator')
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const formattedBots: TBotItem[] = [];
        response.forEach(res => {
          formattedBots.push(formatBot(res));
        });
        this.botCreators.next(formattedBots);
      },
    });
  }

  public fetchCompareBots(): void {
    const response = this._http.get<TBotItemResponse[]>(
      Endpoints.bot.getBotsByType.url('compareBot')
    );
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        const formattedBots: TBotItem[] = [];
        response.forEach(res => {
          formattedBots.push(formatBot(res));
        });
        this.compareBots.next(formattedBots);
      },
    });
  }

  public fetchBotTypes(): void {
    const response = this._http.get<TBotType[]>(Endpoints.bot.fetchBotTypes.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        this.botTypes.next(response);
      },
    });
  }

  public updatePromptHints(botId: string, promptHints: string[]): Observable<TBotItem> {
    return this._http.put<TBotItem>(Endpoints.bot.editBot.url(botId), {
      promptHints,
    });
  }

  public editBot(botItem: any, callback: () => void): void {
    const response = this._http.put<TBotItem>(Endpoints.bot.editBot.url(botItem.id), botItem);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        if (response.botType !== 'creatorItem') {
          const updatedBots = this.bots.value.map(bot => {
            if (bot._id === botItem.id) {
              return {
                ...bot,
                name: response.name,
                title: response.name,
                preSearch: response.preSearch,
                description: response.description,
                behaviour: response.behaviour,
                config: response.config,
                language: response.language,
                bot_status: response.bot_status,
                botModel: response.botModel,
                configuration: response.configuration,
                instruction: response.instruction,
                display_resources: response.display_resources,
                maximum_resources: response.maximum_resources,
                maximum_publications: response.maximum_publications,
                extraConfigurations: response.extraConfigurations,
              };
            }
            return bot;
          });
          this.bots.next(updatedBots);
        } else {
          const updatedBots = this.botCreator.value.bots?.map(bot => {
            if (bot._id === botItem.id) {
              return {
                ...bot,
                ...response,
                // extraConfigurations: response.extraConfigurations // Added field
              };
            }
            return bot;
          });
          this.botCreator.next({
            ...this.botCreator.value,
            bots: updatedBots,
          });
        }
        callback();
      },
    });
  }

  public deleteBot(botId: string, callback: () => void): void {
    const response = this._http.delete(Endpoints.bot.deleteBot.url(botId));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.updateBotsSettings(botId);
        this.bots.next(this.bots.value.filter(bot => bot._id !== botId));
        callback();
      },
    });
  }

  private updateBotsSettings(botId: string): void {
    if (this.primarySummaryBotId.value === botId) {
      this.savePrimarySummaryBot(null);
    }
    if (this.primaryCompareBotId.value === botId) {
      this.savePrimaryCompareBot(null);
    }
    if (this.primaryConversationBotId.value === botId) {
      this.savePrimaryConversationBot(null);
    }
  }

  public removeInnerBot(botCreatorId: string, innerBotId: string, callback: () => void): void {
    const response = this._http.delete(Endpoints.bot.removeInnerBot.url(botCreatorId, innerBotId));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.botCreator.next({
          ...this.botCreator.value,
          bots: this.botCreator.value.bots?.filter(bot => bot._id !== innerBotId),
        });
        callback();
      },
    });
  }

  public updateBotsOrder(botCreatorId: string, bots: TInnerBot[], callback: () => void): void {
    const response = this._http.put(Endpoints.bot.updateBotCreatorBotsOrder.url(botCreatorId), {
      bots: bots.map(bot => bot._id),
    });
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.botCreator.next({
          ...this.botCreator.value,
          bots,
        });
        callback();
      },
    });
  }

  public addInnerBot(botCreatorId: string, innerBot: TInnerBotBody, callback: () => void): void {
    const response = this._http.post(Endpoints.bot.createInnerBot.url(botCreatorId), innerBot);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: response => {
        this.botCreator.next({
          ...this.botCreator.value,
          bots: this.botCreator.value.bots
            ? [...this.botCreator.value.bots, (response as { bot: TInnerBot }).bot]
            : [(response as { bot: TInnerBot }).bot],
        });
        callback();
      },
    });
  }

  public fetchLanguages(): void {
    const response = this._http.get<TLanguage[]>(Endpoints.bot.fetchLanguages.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.languages.next(result);
      },
    });
  }

  public fetchBotCreator(id: string): void {
    const response = this._http.get<TBotCreator>(Endpoints.bot.getBotCreator.url(id));
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.botCreator.next(result);
      },
    });
  }

  getProjectBots(botIds: string[] | undefined): TBotItem[] {
    return this.bots.value.filter(bot => botIds?.includes(bot._id));
  }

  getBotModels(): void {
    const response = this._http.get<TBotModel[]>(BotApi.getBotsModels().url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.botModels.next(result);
      },
    });
  }

  getPrimarySummaryBot(): void {
    const response = this._http.get<TMetadataNew>(Endpoints.bot.fetchPrimarySummaryBot.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.primarySummaryBotId.next(String(result.value));
      },
    });
  }

  getPrimaryCompareBot(): void {
    const response = this._http.get<TMetadataNew>(Endpoints.bot.fetchPrimaryCompareBot.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.primaryCompareBotId.next(String(result.value));
      },
    });
  }

  getPrimaryConversationBot(): void {
    const response = this._http.get<TMetadataNew>(Endpoints.bot.fetchPrimaryConversationBot.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.primaryConversationBotId.next(String(result.value));
      },
    });
  }

  savePrimarySummaryBot(botId: string | null, callback?: () => void): void {
    const body = {
      slug: 'primary_summary_bot',
      name: 'Primary summary agent',
      value: botId,
    };
    const response = this._http.post(Endpoints.bot.updatePrimarySummaryBot.url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.primarySummaryBotId.next(botId);
        if (callback) callback();
      },
    });
  }

  savePrimaryCompareBot(botId: string | null, callback?: () => void): void {
    const body = {
      slug: 'primary_compare_bot',
      name: 'Primary compare agent',
      value: botId,
    };
    const response = this._http.post(Endpoints.bot.updatePrimaryCompareBot.url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.primaryCompareBotId.next(botId);
        if (callback) callback();
      },
    });
  }

  savePrimaryConversationBot(botId: string | null, callback?: () => void): void {
    const body = {
      slug: 'primary_conversation_bot',
      name: 'Primary conversation agent',
      value: botId,
    };
    const response = this._http.post(Endpoints.bot.updatePrimaryConversationBot.url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        this.primaryConversationBotId.next(botId);
        if (callback) callback();
      },
    });
  }

  fetchBotFinalMessage(): void {
    const response = this._http.get<TMetadataNew>(Endpoints.bot.getBotFinalMessage.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: result => {
        this.botFinalMessage.next(String(result.value));
      },
    });
  }

  saveBotFinalMessage(botFinalMessage: string, callback: () => void): void {
    const body = {
      slug: 'bot_final_message',
      name: 'Agent final message',
      value: botFinalMessage,
    };
    const response = this._http.post(Endpoints.bot.updateBotFinalMessage.url, body);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        callback();
      },
    });
  }

  addAssistantQuestion(conversationId: string, message: string) {
    const response = this._http.post(Endpoints.bot.addAssistantQuestion.url, {
      conversationId,
      message,
    });
    response.subscribe({
      error: err => {
        console.error(err);
      },
    });
  }

  sendAnswerAndNextQuestion({
    conversationId,
    answer,
    correct,
    assistantMessage,
    nextQuestion,
  }: {
    conversationId: string;
    answer: string;
    correct: boolean;
    assistantMessage: string;
    nextQuestion?: string;
  }) {
    const response = this._http.post(Endpoints.bot.addUserAnswer.url, {
      conversationId,
      message: answer,
      correct: correct ? 'true' : 'false',
    });
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: () => {
        const response = this._http.post(Endpoints.bot.addAssistantMessage.url, {
          conversationId,
          message: assistantMessage,
        });
        response.subscribe({
          error: err => {
            console.error(err);
          },
          next: () => {
            if (nextQuestion) {
              this.addAssistantQuestion(conversationId, nextQuestion);
            }
          },
        });
      },
    });
  }

  sendQuizContent(conversationId: string, message: string) {
    const response = this._http.post(Endpoints.bot.addAssistantMessage.url, {
      conversationId,
      message,
    });
    response.subscribe({
      error: err => {
        console.error(err);
      },
    });
  }

  public duplicateBot(newName: string, botId: string) {
    return this._http
      .post(Endpoints.bot.duplicateBot.url, {
        newName,
        botId,
      })
      .pipe(
        tap(() => {
          this.fetchBots();
        })
      );
  }

  getBotHistory(bot: TBotItem | TInnerBot) {
    const { url } = BotApi.getBotHistory(bot._id);
    return this._http
      .get<TBotItemHistory[]>(url)
      .pipe(
        map(data =>
          data.sort(
            (a, b) =>
              new Date(b.versionCreatedAt).getTime() - new Date(a.versionCreatedAt).getTime()
          )
        )
      );
  }

  restoreBotHistory(version: TBotItemHistory) {
    const { url } = BotApi.restoreVersion(version._id);
    return this._http.post<TBotItem>(url, null).pipe(tap(() => this.fetchBots()));
  }

  deleteVersion(version: TBotItemHistory) {
    const { url } = BotApi.deleteVersion(version._id);
    return this._http.delete<TBotItem>(url);
  }
}
