<mat-icon 
  *ngIf="promptHints?.length"
  class="action-icon-lightbulb"
  [class.active]="isMenuOpen"
  [matMenuTriggerFor]="hintsMenu"
  matTooltip="Click to see prompt suggestions"
  (menuOpened)="onMenuOpened()"
  (menuClosed)="onMenuClosed()"
>lightbulb_outline</mat-icon>

<mat-menu #hintsMenu="matMenu">
  <div class="menu-title">Prompt suggestions</div>
  <button 
    mat-menu-item 
    *ngFor="let hint of promptHints"
    (click)="updateMessage(hint)"
  >
    <span>{{ hint }}</span>
  </button>
</mat-menu> 