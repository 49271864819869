<span>
    <span class="item-title"
          [matTooltipDisabled]=!needsTooltip(item.name)
          matTooltipPosition="right"
          [matTooltip]=item.name
          (click)="toggleSelection()">
    {{ item.name === undefined ? "New chat" : item.name }} </span>
  </span>
<span class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
    <mat-icon class="icon" (click)="openDeleteItemModal(item)">delete</mat-icon>
  </span>
<span *ngIf="canBeSelected" class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
    <mat-checkbox (change)="toggleSelection()" [(ngModel)]="isSelected"></mat-checkbox>
  </span>
