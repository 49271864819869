import { Component, Inject, OnDestroy } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import * as Mark from 'mark.js';
import { Router } from '@angular/router';
import { TSourceItem } from '@core/interfaces/TSourceItem';
import { FoldersService } from '@shared/services/folders.service';
import { TSourceDetail } from '@core/interfaces/TMessage';
import { TExternalReference } from '@core/api/sources/types';

@Component({
  selector: 'app-source-preview-modal',
  templateUrl: './source-preview-modal.component.html',
  styleUrls: ['./source-preview-modal.component.scss'],
})
export class SourcePreviewModalComponent implements OnDestroy {
  protected source: TSourceItem | undefined;
  protected isLoading = true;
  protected readonly Array = Array;
  private sub: Subscription | undefined;
  protected selectedReferences: number | null = null;
  protected externalReferencesLabel = 'External references';
  protected externalReferences: TExternalReference[] = [];

  constructor(
    private _foldersService: FoldersService,
    private dialogRef: MatDialogRef<SourcePreviewModalComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      sourceDetail: TSourceDetail;
      referencePositionIndex: number | null;
    }
  ) {
    this._foldersService.getSource(this.data.sourceDetail._id!, res => {
      this.source = res;
      if (this.source?.externalReferences && this.data.sourceDetail.externalRefsToDisplay) {
        this.externalReferences = this.source.externalReferences.filter(ref =>
          this.data.sourceDetail.externalRefsToDisplay!.includes(ref._id!)
        );
      }
      this.isLoading = false;
      this.setReferenceIndex(this.data.referencePositionIndex);
      this.externalReferencesLabel = `External references (${this.externalReferences?.length ?? 0})`;
    });
  }

  protected setReferenceIndex(index: number | null) {
    if (!index || !this.data.sourceDetail?.referenceIndexes) return;
    const markText = this.data.sourceDetail.referenceIndexes!.get(index)!.textToHighlight;
    this.selectedReferences = index;
    if (markText) {
      setTimeout(() => {
        this.mark(markText);
      }, 300);
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  goToSource() {
    const url = this.router
      .createUrlTree([
        'sources',
        { folder_id: this.source?.folder[0], source_id: this.source?._id },
      ])
      .toString();
    window.open(url, '_blank');
  }

  goToOriginal() {
    if (this.source?.fileLink) window.open(this.source?.fileLink, '_blank');
  }

  mark(textToSearch: string) {
    const instance = new Mark('.mark-text');

    instance.unmark({
      done: () => {
        const pattern = textToSearch.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
        const reg = new RegExp(pattern, 'gim');
        instance.markRegExp(reg, {
          className: 'marked-text',
          done: (marksTotal: number) => {
            this.navigateToMarked(marksTotal);
          },
        });
      },
    });
  }

  ngOnDestroy(): void {
    this.sub?.unsubscribe();
  }

  private navigateToMarked(marksTotal: number) {
    if (marksTotal > 0) {
      const firstMarked = document.querySelector('mark');
      if (firstMarked) {
        firstMarked.scrollIntoView({ behavior: 'smooth', inline: 'center', block: 'center' });
      }
    }
  }
}
