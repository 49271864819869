import { Component, EventEmitter, Input, Output } from '@angular/core';
import { GroupedResults, TResultsBot } from '@core/interfaces/TResultsItem';
import { ResultsService } from '@shared/services/results.service';
import { MatCheckboxChange } from '@angular/material/checkbox';

@Component({
  selector: 'app-results-list',
  templateUrl: './results-list.component.html',
  styleUrls: ['./results-list.component.scss'],
})
export class ResultsListComponent {
  @Input() public groupedResults: GroupedResults[] = [];
  @Input() public canSelect = false;
  @Output() public itemSelected = new EventEmitter<TResultsBot>();
  @Output() public multipleItemsSelected = new EventEmitter<{
    checked: boolean;
    item: TResultsBot;
  }>();
  @Output() public needOlderItems = new EventEmitter();
  protected selectedItem: TResultsBot | null = null;

  constructor(private _resultsService: ResultsService) {}

  protected getNestedListCSS(group: GroupedResults): string {
    const classes: string[] = [`gingr-content__drawer-content--nested`];
    if (!group.isOpened) {
      classes.push(`gingr-content__drawer-content--nested--hidden`);
    }
    return classes.join(` `);
  }

  protected toggleGroup(group: GroupedResults): void {
    group.isOpened = !group.isOpened;
    this.loadGroupItems(group);
  }

  protected selectItem(item: TResultsBot): void {
    this.itemSelected.emit(item);
    this.selectedItem = item;
  }

  loadGroupItems(group: GroupedResults) {
    group.isLoading = true;

    this._resultsService.fetchResults(1, 200, group.detailsQuery).subscribe(response => {
      group.items = response.results;
      group.isLoading = false;
    });
  }

  groupName(group: GroupedResults): string {
    let name = group.name;
    if (group.count) {
      name += ` (${group.count})`;
    }
    return name;
  }

  afterDeleteItem(id: string): void {
    this.groupedResults.forEach(group => {
      group.items = group.items.filter(item => item._id !== id);
    });
  }

  toggleSelectedItem(item: TResultsBot, ev: MatCheckboxChange): void {
    this.multipleItemsSelected.emit({ checked: ev.checked, item });
  }
}
