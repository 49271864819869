<ng-container
  [ngTemplateOutlet]="menuListTpl"
  [ngTemplateOutletContext]="{ menuList: menu$ | async, parentRoute: [], level: 0 }"
>
</ng-container>

<ng-template #menuListTpl let-menuList="menuList" let-parentRoute="parentRoute" let-level="level">
  <ul navAccordion class="matero-sidemenu level-{{ level }}" [class.submenu]="level > 0">
    <ng-container *ngFor="let menuItem of menuList">
      <ng-template
        [ngxPermissionsOnly]="menuItem.permissions?.only"
        [ngxPermissionsExcept]="menuItem.permissions?.except"
        *appFeatureCheck="menuItem.featureName"
      >
        <li
          *minRole="menuItem.allowedRole"
          navAccordionItem
          class="menu-item"
          routerLinkActive="active"
          [route]="menuItem.route"
          [type]="menuItem.type"
        >
          <a
            class="menu-heading"
            routerLinkActive="activeLink"
            [routerLink]="buildRoute(parentRoute.concat([menuItem.route]))"
            *ngIf="menuItem.type === 'link'"
            matRipple
          >
            <ng-container
              [ngTemplateOutlet]="linkTypeTpl"
              [ngTemplateOutletContext]="{ item: menuItem, level: level }"
            >
            </ng-container>
          </a>

          <a
            class="menu-heading"
            [href]="menuItem.route"
            *ngIf="menuItem.type === 'extLink'"
            matRipple
          >
            <ng-container
              [ngTemplateOutlet]="linkTypeTpl"
              [ngTemplateOutletContext]="{ item: menuItem, level: level }"
            >
            </ng-container>
          </a>

          <a
            class="menu-heading"
            [href]="menuItem.route"
            target="_blank"
            *ngIf="menuItem.type === 'extTabLink'"
            matRipple
          >
            <ng-container
              [ngTemplateOutlet]="linkTypeTpl"
              [ngTemplateOutletContext]="{ item: menuItem, level: level }"
            >
            </ng-container>
          </a>

          <button
            navAccordionToggle
            class="menu-heading menu-toggle"
            *ngIf="menuItem.type === 'sub'"
            matRipple
          >
            <ng-container
              [ngTemplateOutlet]="linkTypeTpl"
              [ngTemplateOutletContext]="{ item: menuItem, level: level }"
            >
            </ng-container>
          </button>

          <ng-container
            [ngTemplateOutlet]="menuListTpl"
            [ngTemplateOutletContext]="{
              menuList: menuItem.children,
              parentRoute: parentRoute.concat([menuItem.route]),
              level: level + 1
            }"
          >
          </ng-container>
        </li>
      </ng-template>
    </ng-container>
  </ul>
</ng-template>

<ng-template #linkTypeTpl let-item="item" let-level="level">
  <ng-container *ngIf="isCustomIcon(item)">
    <mat-icon class="menu-icon" svgIcon="{{ item.icon }}" *ngIf="level === 0"></mat-icon>
  </ng-container>
  <ng-container *ngIf="!isCustomIcon(item)">
    <mat-icon class="menu-icon" *ngIf="level === 0">{{ item.icon }}</mat-icon>
  </ng-container>
  <span class="menu-name">{{ item.name | translate }}</span>
  <span class="menu-label bg-{{ item.label.color }}" *ngIf="item.label">{{
    item.label.value
  }}</span>
  <span class="menu-badge bg-{{ item.badge.color }}" *ngIf="item.badge">{{
    item.badge.value
  }}</span>
  <mat-icon class="menu-caret" *ngIf="item.type !== 'link'">
    {{ item.type === 'sub' ? 'arrow_drop_down' : 'launch' }}
  </mat-icon>
</ng-template>
