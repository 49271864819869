import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

import { TResultsBot, TResultsBotItem } from '@core/interfaces/TResultsItem';
import { allowedToDeleteContentItems } from '../../../enums/content-items';

import { ResultsService } from '@shared/services/results.service';
import { isNotNullAndDefined } from '@shared/utils/isNotNullAndDefined';
import { ConfirmationModalComponent } from '../../components/modals/confirmation-modal/confirmation-modal.component';


@Component({
  selector: 'app-result-item',
  templateUrl: './result-item.component.html',
  styleUrls: ['./result-item.component.scss'],
})
export class ResultItemComponent implements OnChanges {
  @Input() public item: TResultsBot = {} as TResultsBot;
  @Input() public canBeSelected = false;
  @Input() public itemsSelection: TResultsBotItem[] = [];
  @Output() public deletedItemEmiter: EventEmitter<string> = new EventEmitter<string>();
  @Output() public selectedItemEmiter: EventEmitter<void> = new EventEmitter<void>();
  public isSelected = false;

  constructor(
    private _resultsService: ResultsService,
    public dialog: MatDialog
  ) {}

  ngOnChanges(): void {
    // this.canDeleteItem =
    //   allowedToDeleteContentItems.includes(this.item.__type) && !this.canBeSelected;
    // this.isSelected = this.itemsSelection.map(item => item._id).includes(this.item._id);
  }

  public hasSufix(item: TResultsBotItem): boolean {
    return isNotNullAndDefined(item.sufix);
  }

  public toggleSelection(): void {
    this.selectedItemEmiter.emit();
  }

  public openDeleteItemModal(item: TResultsBot): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: { text: `Are you sure you want to delete ${item.name}?` },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      dialogRef.close();
      this._resultsService.cancelableDeleteConversation(item);
      this.deletedItemEmiter.emit(item._id!);
      dialogSubscription.unsubscribe();
    });
  }

  public needsTooltip(title: string | null): boolean {
    if (title) {
      return title.toUpperCase().length > 25;
    }
    return false;
  }
}
