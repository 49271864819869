import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { TSourceItem } from '@core/interfaces/TSourceItem';

import { MultiSummaryService } from '@shared/services/multi-summary.service';
import { StringUtils } from '@shared/utils/stringUtils';

@Component({
  selector: 'app-multi-summary',
  templateUrl: './multi-summary.component.html',
  styleUrls: ['./multi-summary.component.scss'],
})
export class MultiSummaryComponent implements OnInit {
  protected items: TSourceItem[] = [];
  protected isCollapsed = true;
  protected isHighlighted: boolean | null = null;

  constructor(
    protected multiSelectService: MultiSummaryService,
    private router: Router
  ) {}

  highlight() {
    //Don't highlight item when loaded from storage
    if (this.isHighlighted === null) {
      this.isHighlighted = false;
      return;
    }
    if (this.isCollapsed) {
      this.isHighlighted = true;
      setTimeout(() => {
        this.isHighlighted = false;
      }, 500);
    }
  }

  ngOnInit() {
    this.multiSelectService.getItems().subscribe(items => {
      if (items.length === 0) {
        this.isCollapsed = true;
        this.isHighlighted = null;
      }
      //Do not highlight when item is removed
      if (items.length < this.items.length) {
        this.isHighlighted = null;
      }
      this.items = items;
      this.highlight();
    });
  }

  protected toggleCollapsed() {
    this.isCollapsed = !this.isCollapsed;
  }

  makeASummary() {
    const params = StringUtils.urlParamsToStringArray(this.items, '_id', 'sources');
    this.router.navigateByUrl(
      `/bots/${this.multiSelectService.getSummaryBotId()}?${params}&action=summary`
    );
  }

  compare() {
    const params = StringUtils.urlParamsToStringArray(this.items, '_id', 'sources');
    this.router.navigateByUrl(
      `/bots/${this.multiSelectService.getCompareBotId()}?${params}&action=compare`
    );
  }

  askQuestions() {
    const params = StringUtils.urlParamsToStringArray(this.items, '_id', 'sources');
    this.router.navigateByUrl(`/bots/${this.multiSelectService.getConversationBotId()}?${params}&action=ask_questions`);
  }
}
