import { ConversationData, Reference, TResultsBot } from '@core/interfaces/TResultsItem';
import { BaseAdapter } from '@shared/services/bots/adapters/BaseAdapter';
import {
  TAdaptedBotConversation,
  TAdaptedMessage,
  TAdaptedParagraph,
  TMessageType,
} from '@shared/services/bots/types/types';
import { MessageAuthor, ReferenceDetail, TSourceDetail } from '@core/interfaces/TMessage';
import { ReferenceConverter } from '@shared/services/bots/helpers/reference-converter';

export class BotResultsAdapter extends BaseAdapter {
  adapt(data: TResultsBot): TAdaptedBotConversation {
    return {
      id: data._id,
      content: data.content,
      messages: this.adaptMessages(data),
      language: data.language,
      favorite: data.favorite,
      type: 'results',
    };
  }

  protected adaptMessages(data: TResultsBot): TAdaptedMessage[] {
    const results: TAdaptedMessage[] = [];

    data.messages.forEach(originalMsg => {
      const adoptedMsg = {
        _id: originalMsg._id,
        date: originalMsg.date,
        author: originalMsg.type as MessageAuthor,
        paragraphs: [],
        favorite: originalMsg.favorite,
        sources: [],
        type: 'message' as TMessageType,
      } as unknown as TAdaptedMessage;

      const conversationData: ConversationData = {
        conversationId: data._id,
        messageId: '',
        embeddingContent: data.embeddingContent,
        processedTexts: originalMsg.processedSegments!,
        sources: originalMsg.sourceDetails as Reference[],
      };

      adoptedMsg.paragraphs = originalMsg.processedSegments?.length === 0
        ? [{ text: originalMsg.text, references: [] }]
        : this.adaptParagraphs(adoptedMsg, conversationData);

      results.push(adoptedMsg);
    });

    return results;
  }

  private adaptParagraphs(
    adaptedMessage: TAdaptedMessage,
    data: ConversationData
  ): TAdaptedParagraph[] {
    adaptedMessage.sources = data.sources?.map(source => ({
      ...source,
      referenceIndexes: new Map<number, ReferenceDetail>(),
    })) as TSourceDetail[];

    new ReferenceConverter().convert(data, adaptedMessage);

    //Filter sources without references and sort them by the first reference index
    if (adaptedMessage.sources !== undefined) {
      adaptedMessage.sources = adaptedMessage.sources
        .filter(source => source.referenceIndexes && source.referenceIndexes.size > 0)
        .sort((a, b) => {
          const minKeyA = Math.min(...Array.from(a.referenceIndexes?.keys() ?? []));
          const minKeyB = Math.min(...Array.from(b.referenceIndexes?.keys() ?? []));
          return minKeyA - minKeyB;
        });
    }

    return adaptedMessage.paragraphs;
  }
}
