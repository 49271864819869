import { GroupedResults, TResultsPagination } from '@core/interfaces/TResultsItem';
import { ResultsService } from '@shared/services/results.service';
import { BotsService } from '@shared/services/bots.service';
import { Subscription } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ResultDataLoader implements OnDestroy {
  constructor(
    private _resultsService: ResultsService,
    private _botsService: BotsService
  ) {}

  public isLoading = false;
  private _subs: Subscription[] = [];
  public groupedResults: GroupedResults[] = [];
  public pagination: TResultsPagination | null = null;

  public loadDateGroups(page = 1, limit = 100) {
    this.isLoading = true;
    this.groupedResults = [];
    const sub = this._resultsService.getResultsDatesGroups(page, limit).subscribe({
      next: response => {
        this.isLoading = false;
        response.results.forEach(result => {
          this.groupedResults.push({
            detailsQuery: {
              key: 'date',
              value: result.date,
            },
            name: new Date(result.date).toLocaleDateString('en-En', {
              day: '2-digit',
              month: 'long',
              year: 'numeric',
            }),
            items: [],
            count: result.count,
            isOpened: false,
          } as GroupedResults);
        });
        this.pagination = response.pagination;
      },
      error: err => {
        console.error(err);
        this.isLoading = false;
      },
    });
    this._subs.push(sub);
  }

  public loadBotGroups() {
    this.isLoading = true;
    this.groupedResults = [];
    this._subs.push(
      this._botsService.fetchRawBotsData().subscribe(results => {
        results.forEach(bot => {
          this.groupedResults.push({
            detailsQuery: {
              key: 'botID',
              value: bot._id,
            },
            name: bot.name,
            items: [],
            count: 0,
            isOpened: false,
            isLoading: false,
          });
        });
        this.isLoading = false;
      })
    );

  }

  ngOnDestroy() {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
