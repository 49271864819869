<h3 class="p-24 p-l-32 mat-h3">Edit your agents settings</h3>

<div class="p-24 p-t-0 d-flex flex-col w-full">
  <h4 class="mat-h3">Agent final message</h4>
  <form class="form-field-full" [formGroup]="botFinalMessageForm">
    <mat-form-field class="gingr-input-wrapper form-field-full">
      <textarea
        matInput
        placeholder="Agent final message"
        formControlName="botFinalMessage"
        rows="2"
      ></textarea>
    </mat-form-field>
    <button class="m-b-24" mat-raised-button color="accent" (click)="saveBotFinalMessage()">
      Save
    </button>
  </form>
</div>

<div class="d-flex">
<div class="p-24 p-t-0 d-flex flex-col w-30">
  <h4 class="mat-h3">Primary conversation agent</h4>
  <div *ngIf="!botCreators.length" class="m-b-24 m-t-8 suffix">
    To select a primary conversation agent, you must first add a bot creator on bots page.
  </div>
  <div *ngIf="!primaryConversationBotId && botCreators.length" class="m-b-24 m-t-8 suffix">
    To ask a question about specific source, you must select a primary conversation agent.
  </div>

  <form class="form-field-full" [formGroup]="mainConversationBotForm">
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-select formControlName="conversationBot" placeholder="Select bot">
        <mat-option *ngFor="let bot of botCreators" [value]="bot._id">
          {{ bot.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="m-b-24" mat-raised-button color="accent" (click)="savePrimaryConversationBot()">
      Save
    </button>
  </form>
</div>

<div class="p-24 p-t-0 d-flex flex-col w-30">
  <h4 class="mat-h3">Primary summary agent</h4>
  <div *ngIf="!botCreators.length" class="m-b-24 m-t-8 suffix">
    To select a Primary summary agent, you must first add a summary bot on bots page.
  </div>
  <div *ngIf="!primarySummaryBotId && botCreators.length" class="m-b-24 m-t-8 suffix">
    To use source summary, you must select a Primary summary agent.
  </div>

  <form class="form-field-full" [formGroup]="mainSummaryBotForm">
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-select formControlName="summaryBot" placeholder="Select bot">
        <mat-option *ngFor="let sbot of botCreators" [value]="sbot._id">
          {{ sbot.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="m-b-24" mat-raised-button color="accent" (click)="savePrimarySummaryBot()">
      Save
    </button>
  </form>
</div>

<div class="p-24 p-t-0 d-flex flex-col w-30">
  <h4 class="mat-h3">Primary compare agent</h4>
  <div *ngIf="!botCreators.length" class="m-b-24 m-t-8 suffix">
    To select a primary compare agent, you must first add a compare bot on bots page.
  </div>
  <div *ngIf="!primaryCompareBotId && botCreators.length" class="m-b-24 m-t-8 suffix">
    To use source compare, you must select a primary compare agent.
  </div>

  <form class="form-field-full" [formGroup]="mainCompareBotForm">
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-select formControlName="compareBot" placeholder="Select bot">
        <mat-option *ngFor="let cbot of botCreators" [value]="cbot._id">
          {{ cbot.name }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="m-b-24" mat-raised-button color="accent" (click)="savePrimaryCompareBot()">
      Save
    </button>
  </form>
</div>
</div>

<div class="p-24 p-t-0 d-flex flex-col w-full">
  <h4 class="mat-h3">Master status for summary and compare bots</h4>
  <form class="form-field-full" [formGroup]="masterBotStatusForm">
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-select formControlName="status" placeholder="Select status">
        <mat-option [value]="null">
          Remove
        </mat-option>
        <mat-option *ngFor="let status of botStatuses" [value]="status">
          {{ status.replace('_', ' ') | firstUpper }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="m-b-24" mat-raised-button color="accent" (click)="saveMasterStatus()">
      Save
    </button>
  </form>
</div>

<div class="p-24 p-t-0 d-flex flex-col w-full">
  <h4 class="mat-h3">Bot engine</h4>
  <form class="form-field-full" [formGroup]="botEngineForm">
    <mat-form-field class="gingr-input-wrapper w-full">
      <mat-select formControlName="engine" placeholder="Select status">
        <mat-option *ngFor="let engine of Object.values(BotEngine)" [value]="engine">
          {{ engine | firstUpper }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <button class="m-b-24" mat-raised-button color="accent" (click)="saveBotEngine()">
      Save
    </button>
  </form>
</div>
