<mat-drawer-container class="gingr-content">

  <mat-drawer class="gingr-content__drawer" #drawer [opened]="drawerOpened" mode="side">
    <div class="drawer-content">
      <div class="gingr-content__drawer__header d-flex justify-content-between">
        <label>Results</label>
        <mat-button-toggle-group *ngIf="!canSelectResults" [(ngModel)]="selectedOption" (change)="onToggleChange($event)">
          <mat-button-toggle value="byBot">By Bot</mat-button-toggle>
          <mat-button-toggle value="byDate">By Date</mat-button-toggle>
        </mat-button-toggle-group>
        <div class="d-flex top-buttons">
          <button mat-icon-button *ngIf="canSelectResults" (click)="openDeleteMultipleModal()">
            <mat-icon class="icon">delete</mat-icon>
          </button>
          <button mat-icon-button (click)="toggleSelectAll()">
            <mat-icon class="icon" [ngClass]="canSelectResults ? 'select-all' : ''"
            >select_all
            </mat-icon>
          </button>
        </div>
      </div>
      <div class="results-container">

        <mat-progress-spinner *ngIf="resultDataLoader.isLoading; else list" class="spinner" [color]="'accent'"
                              [mode]="'indeterminate'"></mat-progress-spinner>
        <ng-template #list>
          <app-results-list
            (multipleItemsSelected)="multipleItemsSelectedHandler($event)"
            [canSelect]="canSelectResults"
            [groupedResults]="resultDataLoader.groupedResults"
            (itemSelected)="setSelectedItem($event)"
          ></app-results-list>
        </ng-template>

      </div>
    </div>
  </mat-drawer>
  <mat-drawer-content class="gingr-content__drawer-content">
    <ng-container *ngIf="selectedItem">
      <app-bot-results
        [botId]="selectedItem.botID"
        [showTalkingAvatar]="true"
      ></app-bot-results>
      <!--        [toggleStarCallback]="toggleStarConversationCallback.bind(this)"-->
      <!--        *ngIf="!isBotSelected"-->
      <!--      <app-bots-content *ngIf="selectedItem" [selectedBot]="selectedItem"></app-bots-content>-->
    </ng-container>

    <button class="drawer-toggle" color="secondary" mat-raised-button (click)="toggleDrawer()">
      <mat-icon *ngIf="drawerOpened">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!drawerOpened">keyboard_arrow_right</mat-icon>
    </button>
  </mat-drawer-content>
</mat-drawer-container>
