import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { debounceTime, tap } from 'rxjs/operators';

import { AuthService, User } from '@core/authentication';

@Component({
  selector: 'app-user',
  template: `
    <button class="avatar-wrapper" mat-button [matMenuTriggerFor]="menu">
      <mat-icon class="avatar">person</mat-icon>
      <div class="user-name">{{ displayUserName }}</div>
    </button>

    <mat-menu #menu="matMenu">
      <button routerLink="/profile/settings" mat-menu-item>
        <mat-icon>edit</mat-icon>
        <span>{{ 'edit_profile' | translate }}</span>
      </button>
      <button *allowedRole="'admin'" routerLink="/profile/api-settings" mat-menu-item>
        <mat-icon>lock</mat-icon>
        <span>API Access Settings</span>
      </button>
      <button *allowedRole="'admin'" routerLink="/profile/bots-settings" mat-menu-item>
        <mat-icon class="menu-icon" svgIcon="bot-eye-small"></mat-icon>
        <span>Agents Settings</span>
      </button>
      <button *allowedRole="'admin'" routerLink="/app-settings" mat-menu-item>
        <mat-icon>settings</mat-icon>
        <span>General Settings</span>
      </button>
      <button *allowedRole="'admin'" routerLink="/app-users" mat-menu-item>
        <mat-icon>group</mat-icon>
        <span>Users</span>
      </button>
      <button mat-menu-item (click)="logout()">
        <mat-icon>exit_to_app</mat-icon>
        <span>{{ 'logout' | translate }}</span>
      </button>
    </mat-menu>
  `,
  styles: [
    `
      .avatar-wrapper {
        display: flex;
        height: 100%;
        padding: 8px 0;
        flex-direction: column;
        align-items: center;
        .avatar {
          width: auto;
          height: 32px;
          font-size: 32px;
          margin: 0;
          background: none;
          color: var(--icon-bg);
        }
      }
      .user-name {
        width: 90px;
        font-size: 12px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `,
  ],
})
export class UserComponent implements OnInit {
  user!: User;
  displayUserName = '';

  constructor(
    private router: Router,
    private auth: AuthService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.auth
      .user()
      .pipe(
        tap(user => {
          this.user = user;
          this.displayUserName = user.displayName || user.name || user.email || 'user';
          // if username is email remove everything after @
          if (this.displayUserName.includes('@')) {
            this.displayUserName = this.displayUserName.split('@')[0];
            return;
          }
        }),
        debounceTime(10)
      )
      .subscribe(() => this.cdr.detectChanges());
  }

  logout() {
    this.auth.logout().subscribe(() => this.router.navigateByUrl('/auth/login'));
  }
}
