<!--<div class="gingr-content__drawer">-->
  <ul class="gingr-content__drawer-content gingr-content__drawer-content--grouped">
    <li *ngFor="let group of groupedResults" class="gingr-content__drawer-content__group-item">
          <span class="d-flex justify-content-between w-full">
            <span (click)="toggleGroup(group)" class="group-name">
              {{ groupName(group) }}
            </span>
            <button class="group-name" mat-icon-button (click)="toggleGroup(group)">
              <mat-icon *ngIf="!group.isOpened">keyboard_arrow_left</mat-icon>
              <mat-icon *ngIf="group.isOpened">keyboard_arrow_down</mat-icon>
            </button>
          </span>

      <ul class="w-full" [ngClass]="getNestedListCSS(group)">
<!--        group sparator-->
<!--        <div class="m-y-8 d-flex justify-content-between">-->
<!--          <span class="empty-list-indicator">Yesterday111</span>-->
<!--        </div>-->
        <app-spinner *ngIf="group.isLoading" message="" [small]="true"></app-spinner>

        <li *ngFor="let item of group.items" class="flex-row" [ngClass]="{ selected: selectedItem?._id === item._id }">
          <mat-checkbox *ngIf="canSelect" (change)="toggleSelectedItem(item, $event)">
          </mat-checkbox>
          <app-result-item
            (selectedItemEmiter)="selectItem(item)"
            (deletedItemEmiter)="afterDeleteItem($event)"
            class="results-content-result-item"
            [item]="item"
          ></app-result-item>

        </li>
      </ul>
    </li>

  </ul>
<!--</div>-->
<!--          [groups]="groupedResults"-->
<!--          (deletedItemEmiter)="afterDeleteItem($event)"-->
<!--          (selectedItemEmiter)="toggleSelectedItem(item)"-->
<!--          [canBeSelected]="canSelectResults"-->
<!--          [itemsSelection]="itemsSelection"-->
