import { Component, Inject } from '@angular/core';

import { environment } from '@env/environment';

import { StringUtils } from '@shared/utils/stringUtils';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'app-branding',
  templateUrl: './branding.component.html',
  styles: [
    `
      .brand-logo {
        max-height: 60px;
        object-fit: contain;
      }
    `,
  ],
})
export class BrandingComponent {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  get isDarkMode(): boolean {
    return this.document.documentElement.classList.contains('theme-dark');
  }

  get brandLogo(): string {
    return this.isDarkMode ? this.brandLogoPath : this.brandLogoPathLight;
  }

  public brandLogoPath = StringUtils.brandResource('logo');
  public brandLogoPathLight = StringUtils.brandResource('logoLight');
  public brandLogoWidth = environment.logoWidthPx ?? '160px';
}
