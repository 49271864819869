import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { Subscription } from 'rxjs';

import { TBotItem } from '@core/interfaces/TBotItem';

import { BotsService } from '@shared/services/bots.service';
import { CommonService } from '@shared/services/common.service';
import { BotEngineEnum, botStatuses } from '@core/interfaces/TBot';
import { AppSettingsService } from '@shared/services/settings/app-settings.service';
import { MetadataService, MetadataSlugs } from '@shared/services/metadata.service';

@Component({
  selector: 'app-bots-settings',
  templateUrl: './bots-settings.component.html',
  styleUrls: ['./bots-settings.component.scss'],
})
export class BotsSettingsComponent implements OnInit, OnDestroy {
  protected readonly Object = Object;
  protected readonly BotEngine = BotEngineEnum;
  protected readonly botStatuses = botStatuses;

  private _subs: Subscription[] = [];
  primarySummaryBotId: string | null = null;
  primaryCompareBotId: string | null = null;
  primaryConversationBotId: string | null = null;
  botFinalMessage: string | null = null;
  summaryBots: TBotItem[] = [];
  compareBots: TBotItem[] = [];
  botCreators: TBotItem[] = [];
  public botFinalMessageForm = this.fb.group({
    botFinalMessage: [''],
  });
  public mainSummaryBotForm = this.fb.group({
    summaryBot: [''],
  });
  public mainCompareBotForm = this.fb.group({
    compareBot: [''],
  });
  public mainConversationBotForm = this.fb.group({
    conversationBot: [''],
  });
  public masterBotStatusForm = this.fb.group({
    status: [''],
  });
  public botEngineForm = this.fb.group({
    engine: [BotEngineEnum.Azure],
  });

  constructor(
    private _commonService: CommonService,
    private _botsService: BotsService,
    private fb: FormBuilder,
    private _appSettingsService: AppSettingsService,
    private _metadataService: MetadataService
  ) {}

  ngOnInit(): void {
    this._botsService.fetchSummaryBots();
    this._botsService.getPrimarySummaryBot();
    this._botsService.fetchCompareBots();
    this._botsService.getPrimaryCompareBot();
    this._botsService.fetchBotCreators();
    this._botsService.getPrimaryConversationBot();
    this._botsService.fetchBotFinalMessage();

    this._subs.push(
      this._botsService.getSummaryBotsObservable().subscribe(res => {
        this.summaryBots = res;
      })
    );
    this._subs.push(
      this._botsService.getPrimarySummaryBotIdObservable().subscribe(res => {
        this.primarySummaryBotId = res;
        this.mainSummaryBotForm.patchValue({
          summaryBot: res,
        });
      })
    );

    this._subs.push(
      this._botsService.getCompareBotsObservable().subscribe(res => {
        this.compareBots = res;
      })
    );
    this._subs.push(
      this._botsService.getPrimaryCompareBotIdObservable().subscribe(res => {
        this.primaryCompareBotId = res;
        this.mainCompareBotForm.patchValue({
          compareBot: res,
        });
      })
    );

    this._subs.push(
      this._botsService.getBotCreatorsObservable().subscribe(res => {
        this.botCreators = res;
      })
    );
    this._subs.push(
      this._botsService.getPrimaryConversationBotIdObservable().subscribe(res => {
        this.primaryConversationBotId = res;
        this.mainConversationBotForm.patchValue({
          conversationBot: res,
        });
      })
    );
    this._subs.push(
      this._botsService.getBotFinalMessageObservable().subscribe(res => {
        this.botFinalMessage = res;
        this.botFinalMessageForm.patchValue({
          botFinalMessage: res,
        });
      })
    );
    this._subs.push(
      this._appSettingsService.getSettingsAsObservable().subscribe(value => {
        this.masterBotStatusForm.patchValue({ status: <string>value.master_bot_status });
      })
    );
    this._subs.push(
      this._metadataService.get<BotEngineEnum>(MetadataSlugs.BotEngine).subscribe(metadata => {
        this.botEngineForm.controls.engine.patchValue(metadata.value);
      })
    );
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  public saveBotFinalMessage(): void {
    const botFinalMessage = this.botFinalMessageForm.controls.botFinalMessage.value;

    this._botsService.saveBotFinalMessage(botFinalMessage || '', () => {
      this._commonService.openSnackBar(`Agent final message is successfully updated`);
    });
  }

  public savePrimarySummaryBot(): void {
    const summaryBotId = this.mainSummaryBotForm.controls.summaryBot.value;
    if (!summaryBotId) return;
    this._botsService.savePrimarySummaryBot(summaryBotId, () => {
      this._commonService.openSnackBar(`Primary summary agent is successfully updated`);
    });
  }

  public savePrimaryCompareBot(): void {
    const compareBotId = this.mainCompareBotForm.controls.compareBot.value;
    if (!compareBotId) return;
    this._botsService.savePrimaryCompareBot(compareBotId, () => {
      this._commonService.openSnackBar(`Primary compare agent is successfully updated`);
    });
  }

  public savePrimaryConversationBot(): void {
    const conversationBotId = this.mainConversationBotForm.controls.conversationBot.value;
    if (!conversationBotId) return;
    this._botsService.savePrimaryConversationBot(conversationBotId, () => {
      this._commonService.openSnackBar(`Primary conversation agent is successfully updated`);
    });
  }

  protected saveMasterStatus(): void {
    this._appSettingsService.storeSettingMetadata(
      'master_bot_status',
      this.masterBotStatusForm.controls.status.value,
      () => {
        this._commonService.openSnackBar('Master status is successfully updated');
      }
    );
  }

  protected saveBotEngine(): void {
    this._subs.push(
      this._metadataService
        .set(MetadataSlugs.BotEngine, this.botEngineForm.controls.engine.value)
        .subscribe({
          next: () => this._commonService.openSnackBar('Bot engine changed successfully'),
        })
    );
  }
}
