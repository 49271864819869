<div class="modal-ref">
  <h2>{{ source?.name }}</h2>
  <div class="tabs-container">
    <mat-tab-group color="accent" dynamicHeight>
      <mat-tab label="Sources">
        <div>

          <div class="ref-selector-container">
          <span
            *ngFor="let ref of (data.sourceDetail.referenceIndexes ? Array.from(data.sourceDetail.referenceIndexes.entries()) : [])"
            class="ref-selector" [ngClass]="{active: selectedReferences === ref[0]}"
            (click)="setReferenceIndex(ref[0])">Ref. {{ ref[0] }}
          </span>
          </div>
          <div class="source-container">
            <app-spinner *ngIf="isLoading"></app-spinner>
            <div class="mark-text">
              {{ source?.content }}
            </div>
          </div>
        </div>

      </mat-tab>
      <mat-tab [label]="externalReferencesLabel" [disabled]="externalReferences?.length === 0">
        <div *ngIf="externalReferences?.length ?? 0 > 0">
          <h3>External references</h3>
          <div class="modal--content">
            <p *ngFor="let extRef of externalReferences" class="m-0 p-0">
              <a [href]="extRef.referenceLink" target="_blank">{{ extRef.referenceText }}</a>
            </p>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </div>


  <div *ngIf="!isLoading" class="d-flex align-items-center m-t-16 justify-content-between">

    <button
      mat-raised-button
      color="accent"
      (click)="cancel()">
      Close
    </button>

    <div>
      <button
        mat-raised-button
        class="m-r-16"
        color="accent"
        [disabled]="!source"
        (click)="goToSource()">
        Go to source
      </button>
      <button
        mat-raised-button
        color="accent"
        [disabled]="!source?.fileLink"
        (click)="goToOriginal()">
        Go to original
      </button>
    </div>
  </div>
</div>
