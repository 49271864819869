import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-original-content',
  templateUrl: './original-content.component.html',
  styleUrls: ['./original-content.component.scss'],
})
export class OriginalContentComponent {
  @Input() content: string | undefined = undefined;
}
