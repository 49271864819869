import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, map, Observable, Subscription, timer } from 'rxjs';

import { Endpoints } from '@core/interfaces/Endpoints';
import { TJobStatus } from '@core/interfaces/TJobStatus';

@Injectable({
  providedIn: 'root',
})
export class JobStatusService {
  private jobsStatus: BehaviorSubject<TJobStatus[]> = new BehaviorSubject<TJobStatus[]>([]);

  constructor(private _http: HttpClient) {}

  public getJobsStatusObservable(): Observable<TJobStatus[]> {
    return this.jobsStatus.asObservable();
  }

  public fetchJobsStatus(): void {
    const response = this._http.get<TJobStatus[]>(Endpoints.APIs.jobsStatus.url);
    response.subscribe({
      error: err => {
        console.error(err);
      },
      next: res => {
        this.jobsStatus.next(res);
      },
    });
  }

  public statusPoolingSubscribe(): Subscription {
    return timer(0, 1000 * 60)
      .pipe(
        map(() => {
          this.fetchJobsStatus();
        })
      )
      .subscribe();
  }
}
