import { Component, OnDestroy, OnInit } from '@angular/core';
import { TResultsBot } from '@core/interfaces/TResultsItem';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { Subscription } from 'rxjs';
import { resultsConversationRegex } from '@shared/utils/routing';
import { ArrayUtils } from '@shared/utils/arrayUtils';
import { BotResultsAdapter } from '@shared/services/bots/adapters/BotResultsAdapter';
import { BotResultsLegacyAdapter } from '@shared/services/bots/adapters/BotResultsLegacyAdapter';
import { BotMessagesService } from '@shared/services/bots/bot-messages.service';
import { ResultDataLoader } from './result-data-loader';
import { ResultsService } from '@shared/services/results.service';
import { ConfirmationModalComponent } from '../components/modals/confirmation-modal/confirmation-modal.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss'],
})
export class ResultsComponent implements OnInit, OnDestroy {
  protected drawerOpened = true;
  protected canSelectResults = false;
  protected selectedOption: 'byDate' | 'byBot' = 'byBot';
  protected selectedItem: TResultsBot | null = null;
  private _subs: Subscription[] = [];
  private multipleItemsSelected: TResultsBot[] = [];

  constructor(
    private _resultsService: ResultsService,
    protected resultDataLoader: ResultDataLoader,
    private _router: Router,
    protected dialog: MatDialog,
    private _botMessageService: BotMessagesService
  ) {
    this._subs.push(
      this._router.events.subscribe(event => {
        if (event instanceof NavigationEnd || event instanceof Scroll) {
          this.goToResultByUrl();
        }
      })
    );
  }

  public ngOnInit(): void {
    this.loadGroups();
  }

  onToggleChange(event: any) {
    this.loadGroups();
  }

  private loadGroups() {
    switch (this.selectedOption) {
      case 'byDate':
        this.resultDataLoader.loadDateGroups();
        break;
      case 'byBot':
        this.resultDataLoader.loadBotGroups();
        break;
    }
  }

  public toggleSelectAll(): void {
    this.canSelectResults = !this.canSelectResults;
  }

  public toggleDrawer(): void {
    this.drawerOpened = !this.drawerOpened;
  }

  setSelectedItem(item: TResultsBot): void {
    this.selectedItem = item;
    this.setResultsToComponent(item as any);
    this._router.navigateByUrl(`/results/conversation/${item._id}`);
  }

  public openDeleteMultipleModal(): void {
    const dialogRef = this.dialog.open(ConfirmationModalComponent, {
      data: {
        text: `Are you sure you want remove the selected results?`,
      },
    });
    const dialogSubscription = dialogRef.componentInstance.actionCallback.subscribe(() => {
      const ids = this.multipleItemsSelected.map(i => i._id as string);
      this._resultsService.deleteMultiple(ids);
      dialogRef.close();
      this.multipleItemsSelected = [];
      dialogSubscription.unsubscribe();
      this.loadGroups();
      this.canSelectResults = false;
    });
  }

  public ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }

  protected setResultsToComponent(conversation: TResultsBot): void {
    this._botMessageService.isLoading = true;
    this._resultsService.fetchResultConversation(conversation._id, res => {
      const results = res as unknown as TResultsBot;
      if (ArrayUtils.last(results.messages)?.processedSegments?.length ?? 0 > 0) {
        this._botMessageService.setAdapter(new BotResultsAdapter());
      } else {
        this._botMessageService.setAdapter(new BotResultsLegacyAdapter());
      }

      this._botMessageService.appendMessageViaAdapter(res);
      this._botMessageService.isLoading = false;
    });
  }

  private goToResultByUrl = (): void => {
    const pathMatch = this._router.url.match(resultsConversationRegex);

    if (pathMatch) {
      const conversationId = pathMatch[1];
      if (!this.selectedItem?._id || this.selectedItem?._id !== conversationId) {
        this._resultsService.fetchResultConversation(conversationId, res => {
          this.setResultsToComponent(res);
          this.selectedItem = res as any;
        });
      }
    }
  };

  multipleItemsSelectedHandler(event: { checked: boolean; item: TResultsBot }): void {
    if (event.checked) {
      this.multipleItemsSelected.push(event.item);
    } else {
      this.multipleItemsSelected = this.multipleItemsSelected.filter(
        item => item._id !== event.item._id
      );
    }
  }
}
