import { ApiAccessSettingsComponent } from './settings/api-access-settings/api-access-settings.component';
import { AppSettingsComponent } from './settings/app-settings/app-settings.component';
import { BotsSettingsComponent } from './settings/bots-settings/bots-settings.component';
import { EditProfileComponent } from './settings/edit-profile/edit-profile.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { authGuard } from '@core';
import { environment } from '@env/environment';
import { AdminLayoutComponent } from '@theme/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from '@theme/auth-layout/auth-layout.component';

import { GingrContentComponent } from './components/gingr-content/gingr-content.component';

import { ApisComponent } from './apis/apis.component';
import { BotsComponent } from './bots/bots.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProjectsComponent } from './projects/projects.component';
import { Error403Component } from './sessions/403.component';
import { Error404Component } from './sessions/404.component';
import { Error500Component } from './sessions/500.component';
import { ForgotPasswordComponent } from './sessions/forgot-password/forgot-password.component';
import { LoginComponent } from './sessions/login/login.component';
import { RegisterComponent } from './sessions/register/register.component';
import { SourcesComponent } from './sources/sources.component';
import { UsersComponent } from './settings/users/users.component';
import { roleGuard } from '@core/guards/role.guard';
import { ResultsComponent } from './results/results.component';
import { featureCheckGuard } from '@core/guards/feature.guard';

const routes: Routes = [
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [authGuard],
    canActivateChild: [authGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      { path: 'dashboard', component: DashboardComponent },
      { path: '403', component: Error403Component },
      { path: '404', component: Error404Component },
      { path: '500', component: Error500Component },
      {
        path: 'results',
        component: ResultsComponent,
        children: [
          { path: ':id', component: GingrContentComponent },
          { path: 'conversation/:id', component: GingrContentComponent },
        ],
      },
      {
        path: 'bots',
        component: BotsComponent,
        children: [{ path: ':id', component: BotsComponent }],
      },
      {
        path: 'projects',
        component: ProjectsComponent,
        children: [{ path: ':id', component: GingrContentComponent }],
      },
      {
        path: 'sources',
        component: SourcesComponent,
      },
      {
        path: 'sources/materials',
        component: SourcesComponent,
      },
      {
        path: 'sources/batch-upload',
        canActivate: [roleGuard],
        loadChildren: () =>
          import('../modules/batch-upload/batch-upload.module').then(m => m.BatchUploadModule),
      },
      { path: 'apis', component: ApisComponent },
      {
        path: 'slr',
        canActivate: [roleGuard, featureCheckGuard('slr')],
        loadChildren: () => import('../features/slr/slr.module').then(m => m.SlrModule),
      },
      { path: 'profile/settings', component: EditProfileComponent },
      { path: 'profile/api-settings', component: ApiAccessSettingsComponent },
      { path: 'profile/bots-settings', component: BotsSettingsComponent },
      { path: 'app-settings', component: AppSettingsComponent },
      { path: 'app-users', component: UsersComponent, canActivate: [roleGuard] },
    ],
  },
  {
    path: 'auth',
    component: AuthLayoutComponent,
    children: [
      { path: 'login', component: LoginComponent },
      { path: 'register', component: RegisterComponent },
      { path: 'forgot-password', component: ForgotPasswordComponent },
    ],
  },
  { path: '**', redirectTo: 'dashboard' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: environment.useHash,
    }),
  ],
  exports: [RouterModule],
})
export class RoutesRoutingModule {}
