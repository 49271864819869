import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewEncapsulation,
} from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Subscription } from 'rxjs';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit, OnDestroy {
  showSearch = false;
  private _subs: Subscription[] = [];
  @HostBinding('class') class = 'matero-header';

  @Input() public showToggle = true;

  @Output() public toggleSidenavNotice = new EventEmitter<void>();

  constructor(
    private router: Router,
  ) {
    this._subs.push(
      this.router.events.subscribe(event => {
        if (event instanceof NavigationEnd) {
          this.setShowSearchInput();
        }
      })
    );
  }

  ngOnInit() {
    this.setShowSearchInput();
  }

  private setShowSearchInput(): void {
    const firstSegment = this.router.url.split(';')[0].split('/')[1];
    this.showSearch = ['sources', 'results'].includes(firstSegment);
  }

  ngOnDestroy(): void {
    this._subs.forEach(sub => sub.unsubscribe());
  }
}
