<ng-container *ngIf="content">
  <mat-expansion-panel hideToggle class="original-content-panel">
    <mat-expansion-panel-header expandedHeight="48px" collapsedHeight="48px">
      <mat-panel-title>
        <span class="panel-title">Show original content</span>
      </mat-panel-title>
      <mat-panel-description>
        <mat-icon class="expand-icon">expand_more</mat-icon>
      </mat-panel-description>
    </mat-expansion-panel-header>
    <p class="panel-content">{{ content }}</p>
  </mat-expansion-panel>
</ng-container>
