<div class="playlist" *ngIf="videos.length > 0">
  <div class="playlist__player">
    <app-stream-player
      [controlsProhibited]="true"
      [videoUrl]="videoData"
      [videoDimensions]="{ width: 640, height: 360 }"
      (videoEnded)="onVideoEnded()"
    ></app-stream-player>
  </div>
  <div class="scroll-content">
    <div class="playlist__selector">
      <div
        class="playlist__selector-button"
        *ngFor="let video of videos; let i = index"
        (click)="getVideo(video.avatarVideo, i)"
        [class.active]="activeVideoIndex === i"
      >
        <span class="truncate"><strong>Topic {{ i + 1 }}: </strong>{{ video.displayTitle }}</span>
        <mat-icon (click)="download(video.avatarVideo); $event.stopPropagation()">file_download</mat-icon>
      </div>
    </div>
  </div>
</div>
