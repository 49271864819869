import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '@env/environment';

@Directive({
  selector: '[appFeatureCheck]',
})
export class FeatureCheckDirective implements OnInit {
  @Input('appFeatureCheck') featureName = '';

  allowedFeatures: string[] = environment.allowedFeatures ?? [];

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  ngOnInit(): void {
    if (this.isFeatureAllowed()) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else {
      this.viewContainer.clear();
    }
  }

  private isFeatureAllowed(): boolean {
    if (!this.featureName) {
      return true;
    }
    return this.allowedFeatures.includes(this.featureName);
  }
}
