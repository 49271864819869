<pre *ngIf="referenceSources.length > 0">{{ referenceLabel }}:</pre>
<div class="m-t-8 d-flex references-content" *ngFor="let rsrc of referenceSources; index as i">
  <div class="d-flex">
    <ng-container *ngIf="rsrc">
      <app-source-preview-button
        [referencePositionIndex]="ref[0]"
        *ngFor="let ref of (rsrc.referenceIndexes ? Array.from(rsrc.referenceIndexes.entries()) : [])"
        [sourceDetail]="rsrc"
      >
      </app-source-preview-button>

    </ng-container>
    <div>
      <a *ngIf="rsrc" [href]="getSourceLink(rsrc)" target="_blank">{{ rsrc.references || rsrc.name }}</a>
      <app-source-preview-button [sourceDetail]="rsrc"></app-source-preview-button>
    </div>
  </div>
</div>
