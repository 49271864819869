import { Language } from './language';

import {
  TAddBotResponse,
  TAskBotBody,
  TBotCreatorBody,
  TBotItemRequestBody,
  TInnerBotBody,
  TMainBotType,
} from '@core/interfaces/TBot';
import { environment } from '@env/environment';

import { allowedStatuses } from '../../constants/status';

import { TAIInstruction } from './TAIInstruction';
import { TJobStatus } from './TJobStatus';
import { TMetadata, TMetadataNew } from './TMetadata';
import { TProjectConfig } from './TProjectConfig';
import { TSearchIn, TSearchResponse } from './TSearch';
import { SourceSortField } from './TSources';
import { SortDirection } from '@angular/material/sort';

export type UserRole = `admin` | `user`;

export interface TBaseUser {
  _id?: string;
  is2FAEnabled?: boolean;
  username: string;
  roles: UserRole[];
}

export interface TUser<T> extends TBaseUser {
  //metadata: Record<string, T>;
  metadata: TMetadata[];
}

export const testUser: TUser<string> = {
  metadata: [
    {
      slug: `1`,
      value: ``,
    },
  ],
  roles: ['admin'],
  username: `awesomeUser`,
};

export enum EndpointType {
  PUT,
  GET,
  POST,
  DELETE,
}

export interface TPermissionCheckBody {
  username: string;
  action: `generate_email`;
}

export interface TUserLoginBody {
  username: string;
  password: string;
}

export interface TUserEndpoints {
  createUser: {
    url: string;
    response: { status: `created` };
    type: EndpointType.POST;
    body: TUser<string>;
  };
  readUserByUsername: {
    url: (username: string) => string;
    response: TUser<string>;
    type: EndpointType.GET;
  };
  updateUserByUsername: {
    url: (username: string) => string;
    response: { status: `OK` };
    type: EndpointType.PUT;
  };
  deleteUserByUsername: {
    url: (username: string) => string;
    response: { status: `OK` | `No Content` };
    type: EndpointType.DELETE;
  };
  listUsers: {
    url: string;
    response: TBaseUser[];
    type: EndpointType.GET;
  };
  permissionCheck: {
    url: string;
    response: { allowed: boolean };
    type: EndpointType.POST;
    body: TPermissionCheckBody;
  };
  login: {
    url: string;
    response: { token: string };
    type: EndpointType.POST;
    body: TUserLoginBody;
  };
  checkPassword: {
    url: string;
    response: { status: `OK` };
    type: EndpointType.POST;
    body: {
      username: string;
      password: string;
    };
  };
  changePassword: {
    url: (username: string) => string;
    response: { status: `OK` };
    type: EndpointType.PUT;
    body: {
      oldPassword: string;
      newPassword: string;
    };
  };
  initiatePasswordReset: {
    url: string;
    response: { message: string };
    type: EndpointType.POST;
    body: {
      username: string;
    };
  };
  resetPassword: {
    url: string;
    response: { message: string };
    type: EndpointType.POST;
    body: {
      token: string;
      newPassword: string;
    };
  };
}

export interface TBotEndpoints {
  fetchBots: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  getBotById: {
    url: (botId: string) => string;
    response: { status: string };
    type: EndpointType.GET;
  };
  getBotsByType: {
    url: (botType: TMainBotType) => string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchBotTypes: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  addBot: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: TBotItemRequestBody;
  };
  getBotConfig: {
    url: (botId: string) => string;
    response: { status: string };
    type: EndpointType.GET;
  };
  getBotInstructions: {
    url: (botId: string) => string;
    response: { status: string };
    type: EndpointType.GET;
  };
  editBot: {
    url: (botId: string) => string;
    response: { status: string };
    type: EndpointType.PUT;
    body: TAddBotResponse;
  };
  deleteBot: {
    url: (botId: string) => string;
    response: { status: string };
    type: EndpointType.DELETE;
  };
  askBot: {
    url: (botId: string) => string;
    response: { status: string };
    type: EndpointType.POST;
    body: TAskBotBody;
  };
  getInstruction: {
    url: (slug: string) => string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchResults: {
    url: string;
    type: EndpointType.GET;
    response: { status: `OK` };
  };
  fetchResultsByBot: {
    url: string;
    type: EndpointType.GET;
    response: { status: `OK` };
  };
  fetchResultConversation: {
    url: (conversationId: string) => string;
    type: EndpointType.GET;
    response: { status: `OK` };
  };
  deleteConversation: {
    url: (conversationId: string) => string;
    response: { status: string };
    type: EndpointType.DELETE;
  };
  fetchLanguages: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  createNewBotCreator: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: TBotCreatorBody;
  };
  getBotCreator: {
    url: (botCreatorId: string) => string;
    response: { status: string };
    type: EndpointType.GET;
  };
  createInnerBot: {
    url: (botCreatorId: string) => string;
    response: { status: string };
    type: EndpointType.POST;
    body: TInnerBotBody;
  };
  removeBotCreator: {
    url: (botCreatorId: string) => string;
    response: { status: string };
    type: EndpointType.DELETE;
  };
  removeInnerBot: {
    url: (botCreatorId: string, botId: string) => string;
    response: { status: string };
    type: EndpointType.DELETE;
  };
  updateBotCreator: {
    url: (botCreatorId: string) => string;
    response: { status: string };
    type: EndpointType.PUT;
    body: TBotCreatorBody;
  };
  updateBotCreatorBotsOrder: {
    url: (botCreatorId: string) => string;
    response: { status: string };
    type: EndpointType.PUT;
    body: {
      bots: string[];
    };
  };
  getBotFinalMessage: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchPrimarySummaryBot: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchPrimaryCompareBot: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchPrimaryConversationBot: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  updatePrimarySummaryBot: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { slug: 'primary_summary_bot'; name: `Primary summary agent`; value: `` };
  };
  updatePrimaryCompareBot: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { slug: 'primary_compare_bot'; name: `Primary compare agent`; value: `` };
  };
  updatePrimaryConversationBot: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { slug: 'primary_conversation_bot'; name: `Primary conversation agent`; value: `` };
  };
  updateBotFinalMessage: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { slug: 'bot_final_message'; name: `Agents final message`; value: `` };
  };
  addAssistantQuestion: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { conversationId: string; message: string };
  };
  addUserAnswer: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { conversationId: string; message: string; correct: string };
  };
  addAssistantMessage: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { conversationId: string; message: string };
  };
  duplicateBot: {
    url: string;
    response: { status: string };
    type: EndpointType.POST;
    body: { newName: string; botId: string };
  };
}

export interface TECREndpoints {
  fetchECRLogin: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchECRPassword: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  updateECRLogin: {
    url: string;
    response: { status: string };
    type: EndpointType.PUT;
  };
  updateECRPassword: {
    url: string;
    response: { status: string };
    type: EndpointType.PUT;
  };
  fetchECRProjects: {
    url: string;
    response: { status: string };
    type: EndpointType.GET;
  };
  fetchECRProjectData: {
    url: (projectId: string, sourceType: string) => string;
    response: { status: string };
    type: EndpointType.POST;
    body: {
      projectId: string;
      type: string;
    };
  };
}

export interface TSearchEndpoints {
  search: {
    url: (page: number, limit: number) => string;
    response: TSearchResponse;
    type: EndpointType.POST;
    body: {
      query: string;
      searchIn: TSearchIn;
      folderId?: string;
    };
  };
  instantSearch: {
    url: (pageLimit: number) => string;
    response: TSearchResponse;
    type: EndpointType.POST;
    body: {
      query: string;
      searchIn: TSearchIn;
      folderId?: string;
    };
  };
  getFolderList: {
    url: string;
    response: { status: `OK` };
    type: EndpointType.GET;
  };
  getBotList: {
    url: string;
    response: { status: `OK` };
    type: EndpointType.GET;
  };
}

export enum Status {
  Active,
  Inactive,
  Offline,
}

export interface TSource {
  content?: string;
  name: string;
  fileLink?: string;
  fileUrl: string;
  originalFileName: string;
  status: string;
  metadata: TMetadataNew[];
  source: string;
  folder: string[];
  addedDate?: Date;
  modifiedDate?: Date;
  publicationDate?: string;
  _id?: string;
  references: string;
  priority: number;
  countries?: string[];
}

export interface TSourceFile {
  file: File;
  sourceId: string;
}

export interface TSourceEndpoints {
  createFolder: {
    url: string;
    response: { status: `created` };
    type: EndpointType.POST;
    body: TFolder;
  };
  createSource: {
    url: string;
    response: { status: `created` };
    type: EndpointType.POST;
    body: TSource;
  };
  createSourceFile: {
    url: string;
    response: { status: `created` };
    type: EndpointType.POST;
    body: TSourceFile;
  };
  listFolders: {
    url: string;
    response: TFolder[];
    type: EndpointType.GET;
  };
  updateFolder: {
    url: (folderId: string) => string;
    response: { status: `OK` };
    type: EndpointType.PUT;
  };
  listFolderSources: {
    url: (
      folderId: string,
      page: number,
      limit: number,
      sortField: SourceSortField,
      sortOrder: SortDirection
    ) => string;
    response: { total: number; page: number; limit: number; result: TFolder[] };
    type: EndpointType.GET;
  };
  getSource: {
    url: (sourceId: string) => string;
    response: TSource;
    type: EndpointType.GET;
  };
  updateSource: {
    url: (sourceId: string) => string;
    response: { status: `Updated` };
    type: EndpointType.PUT;
  };
  deleteFolder: {
    url: (folderId: string) => string;
    response: { status: `OK` };
    type: EndpointType.DELETE;
  };
  deleteSource: {
    url: (sourceId: string) => string;
    response: { status: `OK` };
    type: EndpointType.DELETE;
  };
  searchSources: {
    url: (query: string, page: number, pageSize: number) => string;
    response: TSource[];
    type: EndpointType.GET;
  };
  regenerateEmbeddings: {
    url: (sourceId: string) => string;
    response: { status: `OK` };
    type: EndpointType.GET;
  };
}

export const testFolder: TFolder = {
  name: `Folder name`,
  status: Status.Active,
};

export const testSource: TSource = {
  fileUrl: `s3://bucket/file.ext`,
  folder: [],
  metadata: [],
  name: `File Name`,
  originalFileName: `file.txt`,
  source: `Upload`,
  status: `Active`,
  addedDate: new Date(),
  modifiedDate: new Date(),
  _id: '',
  priority: 1,
  references: ``,
};

export const testSourceFile: TSourceFile = {
  file: new File([], `file.txt`),
  sourceId: ``,
};

export interface TFolder {
  name: string;
  status: Status | allowedStatuses;
}

export const TEST_FOLDER: TFolder = {
  name: `Folder name`,
  status: Status.Active,
};

export const TEST_JOB_STATUS: TJobStatus = {
  id: `id`,
  name: `name`,
  status: `completed`,
};

export interface TAPIs {
  addEmbeddingToSource: {
    url: (sourceId: string) => string;
    body: {
      vector: number[];
    };
    response: { status: `created` };
    type: EndpointType.POST;
  };
  listEmbeddingsOfSource: {
    url: (sourceId: string) => string;
    type: EndpointType.GET;
    response: any[]; //TODO add interface for response
  };
  createFolder: {
    url: string;
    type: EndpointType.POST;
    body: TFolder;
    response: { status: `created` };
  };
  listFolders: {
    url: string;
    type: EndpointType.GET;
    response: TFolder[];
  };
  updateFolder: {
    url: (sourceId: string) => string;
    type: EndpointType.PUT;
    body: TFolder;
    response: { status: `OK` };
  };
  deleteFolder: {
    url: (folderId: string) => string;
    type: EndpointType.DELETE;
    response: { status: `OK` };
  };
  listSourcesInFolder: {
    url: (folderId: string, page: number, pageSize: number) => string;
    response: TSource[];
    type: EndpointType.GET;
  };
  jobsStatus: {
    url: string;
    type: EndpointType.GET;
    response: TJobStatus[];
  };
}

export interface TProject {
  bots?: string[];
  name: string;
  status: string;
  metadata?: TMetadata[];
  configuration?: TProjectConfig[];
  instructions?: TAIInstruction[];
  _id?: string;
}

export const TEST_PROJECT: TProject = {
  metadata: [],
  name: `Project Name`,
  status: `Active`,
  _id: ``,
};

export interface TProjects {
  createProject: {
    url: string;
    type: EndpointType.POST;
    body: TProject;
    response: { status: `created` };
  };
  listProjects: {
    url: string;
    type: EndpointType.GET;
    response: TProject[];
  };
  getProjectById: {
    url: (projectId: string) => string;
    response: TProject;
    type: EndpointType.GET;
  };
  updateProject: {
    url: (projectId: string) => string;
    type: EndpointType.PUT;
    body: TProject;
    response: { status: `OK` };
  };
  updateProjectDescription: {
    url: (projectId: string, slug: string) => string;
    type: EndpointType.PUT;
    body: TProject;
    response: { status: `OK` };
  };
  createProjectDescription: {
    url: (projectId: string) => string;
    type: EndpointType.POST;
    body: { slug: ''; name: ``; value: `` };
    response: { status: `OK` };
  };
  deleteProject: {
    url: (projectId: string) => string;
    type: EndpointType.DELETE;
    response: { status: `OK` };
  };
  assignFolderToProject: {
    url: (projectId: string) => string;
    type: EndpointType.POST;
    body: {
      folderId: `folderId`;
    };
    response: { status: `OK` };
  };
  removeFolderFromProject: {
    url: (projectId: string, folderId: string) => string;
    type: EndpointType.DELETE;
    response: { status: `OK` };
  };
  assignUsersToProject: {
    url: (projectId: string) => string;
    type: EndpointType.POST;
    response: { status: `OK` };
    body: {
      usernames: string[];
    };
  };
  removeUserFromProject: {
    url: (projectId: string, username: string) => string;
    type: EndpointType.DELETE;
    response: { status: `OK` };
  };
  addConfigurationToProject: {
    url: (projectId: string) => string;
    type: EndpointType.POST;
    body: TProjectConfig;
    response: { status: `OK` };
  };
  addInstructionsToProject: {
    url: (projectId: string) => string;
    type: EndpointType.POST;
    body: TAIInstruction;
    response: { status: `OK` };
  };
  editInstruction: {
    url: (projectId: string, slug: string) => string;
    type: EndpointType.PUT;
    body: TAIInstruction;
    response: { status: `OK` };
  };
  deleteInstruction: {
    url: (projectId: string, slug: string) => string;
    type: EndpointType.DELETE;
    body: TAIInstruction;
    response: { status: `OK` };
  };
  addBotToProject: {
    url: (botId: string, projectId: string) => string;
    type: EndpointType.POST;
    body: { botId: string; projectId: string };
    response: { status: `OK` };
  };
  removeBotFromProject: {
    url: (botId: string, projectId: string) => string;
    type: EndpointType.DELETE;
    response: { status: `OK` };
  };
}

export interface TEndpoints {
  user: TUserEndpoints;
  sources: TSourceEndpoints;
  APIs: TAPIs;
  projects: TProjects;
  bot: TBotEndpoints;
  ECR: TECREndpoints;
  search: TSearchEndpoints;
}

export const Endpoints: TEndpoints = {
  user: {
    createUser: {
      response: { status: `created` },
      url: `/api/users`,
      type: EndpointType.POST,
      body: testUser,
    },
    readUserByUsername: {
      response: testUser,
      type: EndpointType.GET,
      url: (username: string) => `/api/users/${username}`,
    },
    updateUserByUsername: {
      response: { status: `OK` },
      type: EndpointType.PUT,
      url: (username: string) => `${environment.backendURL}/api/users/${username}`,
    },
    deleteUserByUsername: {
      response: { status: `OK` },
      type: EndpointType.DELETE,
      url: (username: string) => `/api/users/${username}`,
    },
    checkPassword: {
      response: { status: `OK` },
      type: EndpointType.POST,
      url: `${environment.backendURL}/api/users/check-password`,
      body: {
        username: 'user',
        password: 'pass',
      },
    },
    changePassword: {
      response: { status: `OK` },
      type: EndpointType.PUT,
      url: (username: string) => `api/users/${username}/change-password`,
      body: {
        oldPassword: `securePassword`,
        newPassword: `newSecurePassword`,
      },
    },
    listUsers: {
      url: `/api/users`,
      response: [testUser],
      type: EndpointType.GET,
    },
    login: {
      url: `/api/auth/login`,
      response: { token: `aaaxbbbxcccxddd` },
      type: EndpointType.POST,
      body: {
        password: `pass`,
        username: `user`,
      },
    },
    permissionCheck: {
      url: `/api/permissions/check`,
      response: { allowed: true },
      type: EndpointType.POST,
      body: {
        action: `generate_email`,
        username: `user`,
      },
    },
    initiatePasswordReset: {
      url: `/api/auth/forgot-password`,
      response: { message: `Password successfully reset.` },
      type: EndpointType.POST,
      body: {
        username: `user`,
      },
    },
    resetPassword: {
      url: `/api/auth/reset-password`,
      response: { message: `OK` },
      type: EndpointType.POST,
      body: {
        token: ``,
        newPassword: ``,
      },
    },
  },
  sources: {
    createFolder: {
      url: `${environment.backendURL}/api/folders`,
      response: { status: `created` },
      type: EndpointType.POST,
      body: testFolder,
    },
    createSource: {
      url: `${environment.backendURL}/api/source`,
      response: { status: `created` },
      type: EndpointType.POST,
      body: testSource,
    },
    createSourceFile: {
      url: `${environment.backendURL}/api/source/upload`,
      response: { status: `created` },
      type: EndpointType.POST,
      body: testSourceFile,
    },
    deleteFolder: {
      response: { status: `OK` },
      type: EndpointType.DELETE,
      url: (folderId: string) => `${environment.backendURL}/api/folders/${folderId}`,
    },
    deleteSource: {
      response: { status: `OK` },
      type: EndpointType.DELETE,
      url: (sourceId: string) => `${environment.backendURL}/api/source/${sourceId}`,
    },
    getSource: {
      url: (sourceId: string) => `${environment.backendURL}/api/source/${sourceId}`,
      response: testSource,
      type: EndpointType.GET,
    },
    listFolders: {
      url: `${environment.backendURL}/api/folders/list`,
      response: [testFolder],
      type: EndpointType.GET,
    },
    updateFolder: {
      url: (folderId: string) => `${environment.backendURL}/api/folders/${folderId}`,
      response: { status: 'OK' },
      type: EndpointType.PUT,
    },
    listFolderSources: {
      url: (
        folderId: string,
        page: number,
        limit: number,
        sortField: SourceSortField,
        sortOrder: SortDirection
      ) =>
        `${environment.backendURL}/api/source/folder/${folderId}/sources?page=${page}&limit=${limit}&sortField=${sortField}&sortOrder=${sortOrder}`,
      response: { total: 1, page: 1, limit: 10, result: [testFolder] },
      type: EndpointType.GET,
    },
    searchSources: {
      url: (query: string, page: number, pageSize: number) =>
        `${environment.backendURL}/api/sources/search?query=${query}&page=${page}&pageSize=${pageSize}`,
      response: [testSource],
      type: EndpointType.GET,
    },
    updateSource: {
      url: (sourceId: string) => `${environment.backendURL}/api/source/${sourceId}`,
      response: { status: `Updated` },
      type: EndpointType.PUT,
    },
    regenerateEmbeddings: {
      url: (sourceId: string) => `${environment.backendURL}/api/source/regenerateEmbed/${sourceId}`,
      response: { status: `OK` },
      type: EndpointType.GET,
    },
  },
  APIs: {
    addEmbeddingToSource: {
      url: (sourceId: string) => `${environment.backendURL}/api/sources/:${sourceId}/embeddings`,
      body: {
        vector: [0.1, 0.2],
      },
      response: { status: `created` },
      type: EndpointType.POST,
    },
    createFolder: {
      url: `${environment.backendURL}/api/folders`,
      type: EndpointType.POST,
      body: TEST_FOLDER,
      response: { status: `created` },
    },
    deleteFolder: {
      url: (folderId: string) => `${environment.backendURL}/api/folders/:${folderId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    listEmbeddingsOfSource: {
      url: (sourceId: string) => `${environment.backendURL}/api/sources/:${sourceId}/embeddings`,
      type: EndpointType.GET,
      response: [],
    },
    listFolders: {
      url: `${environment.backendURL}/api/folders`,
      type: EndpointType.GET,
      response: [TEST_FOLDER],
    },
    updateFolder: {
      url: (folderId: string) => `${environment.backendURL}/api/folders/:${folderId}`,
      type: EndpointType.PUT,
      body: TEST_FOLDER,
      response: { status: `OK` },
    },
    listSourcesInFolder: {
      url: (folderId: string, page: number, pageSize: number) =>
        `${environment.backendURL}/api/folders/:${folderId}/sources?page=${page}&pageSize=${pageSize}`,
      response: [],
      type: EndpointType.GET,
    },
    jobsStatus: {
      url: `${environment.backendURL}/api/status/jobs`,
      type: EndpointType.GET,
      response: [TEST_JOB_STATUS],
    },
  },
  projects: {
    assignFolderToProject: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}/folders`,
      type: EndpointType.POST,
      body: {
        folderId: `folderId`,
      },
      response: { status: `OK` },
    },
    assignUsersToProject: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}/users`,
      type: EndpointType.POST,
      response: { status: `OK` },
      body: {
        usernames: [],
      },
    },
    createProject: {
      url: `${environment.backendURL}/api/project`,
      type: EndpointType.POST,
      body: TEST_PROJECT,
      response: { status: `created` },
    },
    deleteProject: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    getProjectById: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}`,
      response: TEST_PROJECT,
      type: EndpointType.GET,
    },
    listProjects: {
      url: `${environment.backendURL}/api/project`,
      type: EndpointType.GET,
      response: [TEST_PROJECT],
    },
    removeFolderFromProject: {
      url: (projectId: string, folderId: string) =>
        `${environment.backendURL}/api/project/${projectId}/folders/:${folderId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    removeUserFromProject: {
      url: (projectId: string, username: string) =>
        `${environment.backendURL}/api/project/${projectId}/users/:${username}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    updateProject: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}`,
      type: EndpointType.PUT,
      body: TEST_PROJECT,
      response: { status: `OK` },
    },
    updateProjectDescription: {
      url: (projectId: string, slug: string) =>
        `${environment.backendURL}/api/project/${projectId}/metadata/${slug}`,
      type: EndpointType.PUT,
      body: TEST_PROJECT,
      response: { status: `OK` },
    },
    createProjectDescription: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}/metadata`,
      type: EndpointType.POST,
      body: { slug: '', name: ``, value: `` },
      response: { status: `OK` },
    },
    addConfigurationToProject: {
      url: (projectId: string) =>
        `${environment.backendURL}/api/project/${projectId}/configuration`,
      type: EndpointType.POST,
      body: { slug: ``, status: ``, value: `` },
      response: { status: `OK` },
    },
    addInstructionsToProject: {
      url: (projectId: string) => `${environment.backendURL}/api/project/${projectId}/instructions`,
      type: EndpointType.POST,
      body: { slug: ``, status: ``, value: ``, order: 1 },
      response: { status: `OK` },
    },
    editInstruction: {
      url: (projectId: string, slug: string) =>
        `${environment.backendURL}/api/project/${projectId}/instructions/${slug}`,
      type: EndpointType.PUT,
      body: { slug: ``, status: ``, value: ``, order: 1 },
      response: { status: `OK` },
    },
    deleteInstruction: {
      url: (projectId: string, slug: string) =>
        `${environment.backendURL}/api/project/${projectId}/instructions/${slug}`,
      type: EndpointType.DELETE,
      body: { slug: ``, status: ``, value: ``, order: 1 },
      response: { status: `OK` },
    },
    addBotToProject: {
      url: (botId: string, projectId: string) =>
        `${environment.backendURL}/api/project/${projectId}/bots/${botId}`,
      type: EndpointType.POST,
      body: { botId: '', projectId: '' },
      response: { status: `OK` },
    },
    removeBotFromProject: {
      url: (botId: string, projectId: string) =>
        `${environment.backendURL}/api/project/${projectId}/bots/${botId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
  },
  bot: {
    fetchBots: {
      url: `${environment.backendURL}/api/bots`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    getBotById: {
      url: (botId: string) => `${environment.backendURL}/api/bots/${botId}`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    getBotsByType: {
      url: (botType: TMainBotType) => `${environment.backendURL}/api/bots/types/${botType}`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    fetchBotTypes: {
      url: `${environment.backendURL}/api/bots/types`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    addBot: {
      url: `${environment.backendURL}/api/bots/`,
      type: EndpointType.POST,
      body: { name: '', botType: '', status: '', botModel: '' },
      response: { status: `OK` },
    },
    getBotConfig: {
      url: (botId: string) => `${environment.backendURL}/api/bots/${botId}/configuration`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    getBotInstructions: {
      url: (botId: string) => `${environment.backendURL}/api/bots/${botId}/instructions`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    editBot: {
      url: (botId: string) => `${environment.backendURL}/api/bots/${botId}`,
      type: EndpointType.PUT,
      body: { botType: '', configuration: [], instructions: [], name: '', preSearch: '' },
      response: { status: `OK` },
    },
    deleteBot: {
      url: (botId: string) => `${environment.backendURL}/api/bots/${botId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    askBot: {
      url: (botId: string) => `${environment.backendURL}/api/bots/ask/${botId}`,
      type: EndpointType.POST,
      body: { conversation_id: '', question: '' },
      response: { status: `OK` },
    },
    getInstruction: {
      url: (slug: string) => `${environment.backendURL}/api/metadata/${slug}`,
      response: { status: 'OK' },
      type: EndpointType.GET,
    },
    fetchResults: {
      url: `${environment.backendURL}/api/bots/results`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    fetchResultsByBot: {
      url: `${environment.backendURL}/api/bots/resultsByBots`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    fetchResultConversation: {
      url: (conversationId: string) =>
        `${environment.backendURL}/api/bots/results/${conversationId}`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    deleteConversation: {
      url: (conversationId: string) =>
        `${environment.backendURL}/api/bots/results/${conversationId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    fetchLanguages: {
      url: `${environment.backendURL}/api/bots/languages`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    createNewBotCreator: {
      url: `${environment.backendURL}/api/bots/creator/`,
      type: EndpointType.POST,
      body: { name: '', language: '' as Language, description: '', instruction: '', behaviour: '' },
      response: { status: `OK` },
    },
    getBotCreator: {
      url: (botCreatorId: string) => `${environment.backendURL}/api/bots/creator/${botCreatorId}`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    createInnerBot: {
      url: (botCreatorId: string) =>
        `${environment.backendURL}/api/bots/creator/${botCreatorId}/createBot`,
      type: EndpointType.POST,
      body: {
        name: '',
        description: '',
        instruction: '',
        behaviour: '',
        config: '',
        top_p: 0,
        presence_penalty: 0,
        frequency_penalty: 0,
        temperature: 0,
      },
      response: { status: `OK` },
    },
    removeBotCreator: {
      url: (botCreatorId: string) => `${environment.backendURL}/api/bots/creator/${botCreatorId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    removeInnerBot: {
      url: (botCreatorId: string, botId: string) =>
        `${environment.backendURL}/api/bots/creator/${botCreatorId}/removeBot/${botId}`,
      type: EndpointType.DELETE,
      response: { status: `OK` },
    },
    updateBotCreator: {
      url: (botCreatorId: string) => `${environment.backendURL}/api/bots/creator/${botCreatorId}`,
      type: EndpointType.PUT,
      body: {
        name: '',
        language: '' as Language,
        description: '',
        instruction: '',
        behaviour: '',
        preSearch: '',
        display_resources: false,
        maximum_resources: 0,
        maximum_publications: 0,
      },
      response: { status: `OK` },
    },
    updateBotCreatorBotsOrder: {
      url: (botCreatorId: string) => `${environment.backendURL}/api/bots/creator/${botCreatorId}`,
      type: EndpointType.PUT,
      body: { bots: [] },
      response: { status: `OK` },
    },
    getBotFinalMessage: {
      url: `${environment.backendURL}/api/metadata/bot_final_message`,
      type: EndpointType.GET,
      response: { status: `OK` },
    },
    updateBotFinalMessage: {
      url: `${environment.backendURL}/api/metadata`,
      type: EndpointType.POST,
      body: {
        slug: 'bot_final_message',
        name: `Agents final message`,
        value: ``,
      },
      response: { status: `OK` },
    },
    fetchPrimarySummaryBot: {
      url: `${environment.backendURL}/api/metadata/primary_summary_bot`,
      type: EndpointType.GET,
      response: { status: 'OK' },
    },
    updatePrimarySummaryBot: {
      url: `${environment.backendURL}/api/metadata`,
      type: EndpointType.POST,
      response: { status: 'OK' },
      body: {
        slug: 'primary_summary_bot',
        name: 'Primary summary agent',
        value: '',
      },
    },
    fetchPrimaryCompareBot: {
      url: `${environment.backendURL}/api/metadata/primary_compare_bot`,
      type: EndpointType.GET,
      response: { status: 'OK' },
    },
    updatePrimaryCompareBot: {
      url: `${environment.backendURL}/api/metadata`,
      type: EndpointType.POST,
      response: { status: 'OK' },
      body: {
        slug: 'primary_compare_bot',
        name: 'Primary compare agent',
        value: '',
      },
    },
    fetchPrimaryConversationBot: {
      url: `${environment.backendURL}/api/metadata/primary_conversation_bot`,
      type: EndpointType.GET,
      response: { status: 'OK' },
    },
    updatePrimaryConversationBot: {
      url: `${environment.backendURL}/api/metadata`,
      type: EndpointType.POST,
      response: { status: 'OK' },
      body: {
        slug: 'primary_conversation_bot',
        name: 'Primary conversation agent',
        value: '',
      },
    },
    addAssistantQuestion: {
      url: `${environment.backendURL}/api/bots/trainingTests/addAssistantQuestion`,
      response: { status: 'OK' },
      type: EndpointType.POST,
      body: { conversationId: '', message: '' },
    },
    addUserAnswer: {
      url: `${environment.backendURL}/api/bots/trainingTests/addUserAnswer`,
      response: { status: 'OK' },
      type: EndpointType.POST,
      body: { conversationId: '', message: '', correct: '' },
    },
    addAssistantMessage: {
      url: `${environment.backendURL}/api/bots/trainingTests/addAssistantMessage`,
      response: { status: 'OK' },
      type: EndpointType.POST,
      body: { conversationId: '', message: '' },
    },
    duplicateBot: {
      url: `${environment.backendURL}/api/bots/duplicateBot`,
      response: { status: 'OK' },
      type: EndpointType.POST,
      body: { newName: '', botId: '' },
    },
  },

  ECR: {
    fetchECRLogin: {
      url: `${environment.backendURL}/api/metadata/ecr_login`,
      type: EndpointType.GET,
      response: { status: 'OK' },
    },
    fetchECRPassword: {
      url: `${environment.backendURL}/api/metadata/ecr_password`,
      type: EndpointType.GET,
      response: { status: 'OK' },
    },
    updateECRLogin: {
      url: `${environment.backendURL}/api/metadata/ecr_login`,
      type: EndpointType.PUT,
      response: { status: 'OK' },
    },
    updateECRPassword: {
      url: `${environment.backendURL}/api/metadata/ecr_password`,
      type: EndpointType.PUT,
      response: { status: 'OK' },
    },
    fetchECRProjects: {
      url: `${environment.backendURL}/api/ecr/projects`,
      type: EndpointType.GET,
      response: { status: 'OK' },
    },
    fetchECRProjectData: {
      url: (projectId: string, sourceType: string) =>
        `${environment.backendURL}/api/ecr/fetch/${projectId}/${sourceType}`,
      type: EndpointType.POST,
      response: { status: 'OK' },
      body: {
        projectId: '',
        type: '',
      },
    },
  },
  search: {
    search: {
      url: (page: number, limit: number) =>
        `${environment.backendURL}/api/search?page=${page}&limit=${limit}`,
      response: {} as TSearchResponse,
      type: EndpointType.POST,
      body: {
        query: '',
        searchIn: 'sources',
        folderId: '',
      },
    },
    instantSearch: {
      url: (limit: number) => `${environment.backendURL}/api/search/instant?limit=${limit}`,
      response: {} as TSearchResponse,
      type: EndpointType.POST,
      body: {
        query: '',
        searchIn: 'sources',
        folderId: '',
      },
    },
    getFolderList: {
      url: `${environment.backendURL}/api/search/folders`,
      response: { status: `OK` },
      type: EndpointType.GET,
    },
    getBotList: {
      url: `${environment.backendURL}/api/search/bots`,
      response: { status: `OK` },
      type: EndpointType.GET,
    },
  },
};
``;
