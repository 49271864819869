<mat-drawer-container class="gingr-content">
  <mat-drawer class="gingr-content__drawer" #drawer [opened]="drawerOpened" mode="side">
    <div class="gingr-content__drawer__header">
      <label>Agents</label>
      <button mat-icon-button *allowedRole="'admin'" (click)="addNewItem()" color="accent">
        <mat-icon>add_box</mat-icon>
      </button>
    </div>

    <ng-container>
      <ul class="gingr-content__drawer-content gingr-content__drawer-content--items">
        <li
          *ngFor="let bot of bots"
          class="gingr-content__drawer-content__item"
          [ngClass]="{ selected: selectedBot?._id === bot._id }"
        >
          <span class="gingr-content__drawer-content__item" (click)="selectBot(bot)">
            <ng-container *ngIf="bot.icon">
              <mat-icon class="menu-icon" svgIcon="{{ bot.icon }}"></mat-icon>
            </ng-container>
            <span class="item-title">{{ bot.title }}</span>
          </span>
          <div class="d-flex">
            <span class="d-flex gingr-content__drawer-content__item__sufix delete-icon">
              <mat-icon class="icon" matTooltip="{{ bot.description }}">info</mat-icon>
            </span>
            <app-bot-status-badge
              class="m-r-8"
              [botType]="bot.botType"
              [botStatus]="bot.bot_status"
            >
            </app-bot-status-badge>
          </div>
        </li>
      </ul>
    </ng-container>
  </mat-drawer>
  <mat-drawer-content class="gingr-content__drawer-content">
    <app-bots-content
      *ngIf="selectedBot"
      (deleteItem)="openDeleteModal($event)"
      [selectedBot]="selectedBot"
      [bots]="bots"
    ></app-bots-content>

    <button class="drawer-toggle" color="secondary" mat-raised-button (click)="toggleDrawer()">
      <mat-icon *ngIf="drawerOpened">keyboard_arrow_left</mat-icon>
      <mat-icon *ngIf="!drawerOpened">keyboard_arrow_right</mat-icon>
    </button>
  </mat-drawer-content>
</mat-drawer-container>
